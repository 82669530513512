import {
  CycleBoundaryDrawable,
  Edge,
  Mesh,
  PlanType,
  TransformationLengthDirection,
} from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { environment } from '../../../../environments/environment'
import {
  BACKGROUND_MESH_HIGHLIGHT_COLOR,
  BLACK,
  DANGER_COLOR,
  PREVIEW_COLOR,
  SECONDARY_COLOR,
  TRANSPARENT,
} from '../../../constants/colors'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { flatMap } from '../../../utils/flatMap'
import { AuxiliaryGuideline } from '../model/guidelines/AuxiliaryGuideline'
import { Model } from '../model/Model'
import { AuxiliaryGuidelineItem } from '../model/paper/AuxiliaryGuidelineItem'
import { LengthLabel } from '../model/paper/LengthLabel'
import { CENTER_POINT_LENGTH } from '../model/snapping/constants'
import { WallModel } from '../model/WallModel'
import { AngleInfo } from '../types/AngleInfo'
import { Mode } from '../types/mode'
import { AngleIndicator } from '../util/paper/AngleIndicator'
import { LabelRenderService } from './label-render.service'
import { EdgeSelectionService } from './edge-selection.service'

const POINT_STYLE = {
  fillColor: SECONDARY_COLOR,
  strokeColor: BLACK,
  strokeWidth: 1,
} as paper.Style

const BACKGROUND_POINT_STYLE = {
  fillColor: BACKGROUND_MESH_HIGHLIGHT_COLOR,
  strokeColor: BLACK,
  strokeWidth: 1,
} as paper.Style

const PREVIEW_PATH_STYLE = {
  strokeWidth: 3,
  dashArray: [7, 7],
  fillRule: 'evenodd',
  fillColor: TRANSPARENT,
} as paper.Style

const MIN_CROSSING_POINT_DISTANCE = 3

export abstract class RenderService<MODEL extends Model<Mesh>> {
  public previewModel?: MODEL

  protected angleIndicator: AngleIndicator
  protected labelService: LabelRenderService
  protected layer: paper.Layer
  protected previewLayer: paper.Layer
  protected auxiliaryGuidelineLayer: paper.Layer
  protected meshHighlightLayer: paper.Layer
  protected path: paper.CompoundPath
  protected previewPath: paper.CompoundPath | undefined

  public meshHighlightLayerBlocked = false

  private auxiliaryGuidelines: AuxiliaryGuideline[] = []

  public set angleLabelsVisible(visible: boolean) {
    this.labelService.anglesVisible = visible
  }

  public get angleLabelsVisible(): boolean {
    return this.labelService.anglesVisible
  }

  public set lengthLabelsVisible(visible: boolean) {
    this.labelService.lengthsVisible = visible
  }

  public get lengthLabelsVisible(): boolean {
    return this.labelService.lengthsVisible
  }

  public set meshHighlightVisible(visible: boolean) {
    this.meshHighlightLayer.visible = visible
  }

  public get meshHighlightVisible(): boolean {
    return this.meshHighlightLayer.visible
  }

  public set auxiliaryGuidelinesVisible(visible: boolean) {
    this.auxiliaryGuidelineLayer.visible = visible
  }

  public get auxiliaryGuidelinesVisible(): boolean {
    return this.auxiliaryGuidelineLayer.visible
  }

  public setVisibilitySettings(settings: PlanVisibilitySettings, mode: Mode): void {
    if (mode === Mode.DEFORMATION) {
      this.meshHighlightLayerBlocked = !settings.showGripPoints
      this.meshHighlightLayer.visible = settings.showGripPoints
    }
    this.auxiliaryGuidelineLayer.visible = settings.showAuxiliaryLines
    this.labelService.anglesVisible = settings.showAngles
    this.labelService.lengthsVisible = settings.showDimensions
  }

  constructor(
    protected model: MODEL,
    protected readonly paperScope: paper.PaperScope,
    private readonly planType: PlanType,
    private readonly planVisibilitySettings: PlanVisibilitySettings,
    private readonly edgeSelectionService?: EdgeSelectionService
  ) {
    if (this.edgeSelectionService) {
      paperScope.project.addLayer(this.edgeSelectionService.layerHighlightAreas)
    }

    this.layer = this.createLayer(paperScope, 'Draw Layer')

    if (planType === PlanType.WALL && this.edgeSelectionService) {
      this.edgeSelectionService.layerHighlightAreas.bringToFront()
    }

    this.meshHighlightLayer = this.createLayer(paperScope, 'Deformation Layer')
    this.meshHighlightLayer.visible = false

    this.auxiliaryGuidelineLayer = this.createLayer(paperScope, 'Auxiliary Guideline Layer')

    this.previewLayer = this.createLayer(paperScope, 'Preview Draw Layer')

    this.labelService = new LabelRenderService(paperScope)
    this.angleIndicator = new AngleIndicator(paperScope)

    this.path = new paper.Path()
    this.setVisibilitySettings(planVisibilitySettings, Mode.DEFORMATION)
  }

  set selectionPoint(downpoint: paper.Point | undefined) {
    this.labelService.selectionPoint = downpoint
  }

  get selectionPoint(): paper.Point | undefined {
    return this.labelService.selectionPoint
  }

  set selectedAngle(angleInfo: AngleInfo | undefined) {
    this.labelService.selectedAngle = angleInfo
  }

  get selectedAngle(): AngleInfo | undefined {
    return this.labelService.selectedAngle
  }

  set hideLabelPosition(point: paper.Point | undefined) {
    this.labelService.hideLabelPosition = point
  }

  /**
   * Creates a new named layer and ensures it is added to the current project scope.
   * @param name The name of the layer
   */
  createLayer(scope: paper.PaperScope, name: string): paper.Layer {
    const layer = new paper.Layer()
    layer.name = name
    scope.project.addLayer(layer)

    return layer
  }

  draw(
    cycleBoundaries?: CycleBoundaryDrawable[],
    selectedTWall?: Edge,
    hidePreview: boolean = true
  ): void {
    this.drawMesh()
    if (!hidePreview) {
      this.drawPreviewMesh()
    }

    // creating the highlight areas for the walls for the selection and hover effect
    this.edgeSelectionService?.createHighlightAreas(this.model.getAllEdges(), this.planType)

    this.drawLabels(cycleBoundaries, selectedTWall, hidePreview)
    this.drawPoints(selectedTWall)
    this.drawAuxiliaryGuidelines()
  }

  unhoverAll(): void {
    this.edgeSelectionService?.unhoverAll()
    this.labelService.unhoverAll()
  }

  indicateAngle(startPoint: paper.Point, endPoint: paper.Point): void {
    this.angleIndicator.show(startPoint.x, startPoint.y)
    this.angleIndicator.indicate(startPoint, endPoint)
  }

  removeAngleIndicator(): void {
    this.angleIndicator.hide()
  }

  updateAngleIndicator(): void {
    this.angleIndicator.updateScale()
  }

  private drawPoints(selectedTWall?: Edge): void {
    this.meshHighlightLayer.removeChildren()

    if (environment.debugDrawing) {
      this.meshHighlightLayer.visible = true
    }

    this.model.getPoints().forEach((point) => {
      const circle = new paper.Path.Circle(point, 5)
      if (
        !selectedTWall ||
        point === selectedTWall.startPoint ||
        point === selectedTWall.endPoint
      ) {
        circle.style = POINT_STYLE
      } else {
        circle.style = BACKGROUND_POINT_STYLE
      }
      if (environment.debugDrawing) {
        const text = new paper.PointText({
          content: point.x.toFixed(0) + ' , ' + point.y.toFixed(0),
          fillColor: new paper.Color('blue'),
          justification: 'center',
        })
        text.position = point
        text.fontSize = 13
        text.strokeWidth = 2
        this.meshHighlightLayer.addChild(text)
      }
      this.meshHighlightLayer.addChild(circle)
    })

    this.model.getAllEdges().forEach((edge) => {
      if (edge.startPoint.subtract(edge.endPoint).length > 30) {
        const rect = new paper.Rectangle(
          edge.getCenterPoint().add(new paper.Point(-CENTER_POINT_LENGTH / 2, -5)),
          edge.getCenterPoint().add(new paper.Point(CENTER_POINT_LENGTH / 2, 5))
        )
        const symbol = new paper.Path.Rectangle(rect, new paper.Size(5, 5))
        if (!selectedTWall || edge.isSameEdge(selectedTWall)) {
          symbol.style = POINT_STYLE
        } else {
          symbol.style = BACKGROUND_POINT_STYLE
        }
        const rotation = edge.getDirection().angle
        symbol.rotate(rotation)
        this.meshHighlightLayer.addChild(symbol)
      }
    })
  }

  calculateAuxiliaryLines(
    sourceEdges: Edge[],
    generateOuterEdges: boolean,
    showGuidelinesBetweenSourceLines: boolean
  ): void {
    if (sourceEdges.length > 0) {
      const sourceLines = this.model.getSurroundingLines(sourceEdges, generateOuterEdges)
      const otherLines = this.model.getSurroundingLines(this.model.getAllEdges())

      const auxiliaryLines = sourceLines
        .map((sourceLine) => sourceLine.findOverlappingLines(otherLines))
        .map((overlappingLines) => {
          const onlySourceLines =
            !showGuidelinesBetweenSourceLines &&
            overlappingLines.every((ol) => sourceLines.some((sl) => sl.equals(ol)))

          // <= 1 --> exclude first match which matches to the source line
          if (onlySourceLines || overlappingLines.length <= 1) {
            return null
          }

          const line = overlappingLines.reduce((previousLine, currentLine) =>
            previousLine.extend(currentLine)
          )
          const crossingPoints = flatMap(
            overlappingLines.map((l) => {
              const distance = l.start.getDistance(l.end)
              if (distance >= MIN_CROSSING_POINT_DISTANCE) {
                return [l.start, l.end]
              } else {
                const vectorBetween = l.end.subtract(l.start)
                return [l.start.add(vectorBetween.normalize(vectorBetween.length / 2))]
              }
            })
          )
          return { line, crossingPoints } as AuxiliaryGuideline
        })
        .filter((auxiliaryLine) => auxiliaryLine != null) as AuxiliaryGuideline[]

      this.setAuxiliaryGuidelines(auxiliaryLines)
    }
  }

  setAuxiliaryGuidelines(auxiliaryGuidelines: AuxiliaryGuideline[]): void {
    this.auxiliaryGuidelines = auxiliaryGuidelines
    this.drawAuxiliaryGuidelines()
  }

  protected abstract getMeshStyle(): paper.Style

  protected abstract refreshLabels(
    cycleBoundaries?: CycleBoundaryDrawable[],
    selectedTWall?: Edge
  ): void

  drawLabels(
    cycleBoundaries?: CycleBoundaryDrawable[],
    selectedTWall?: Edge,
    hidePreview: boolean = false
  ): void {
    this.labelService.reset()
    this.refreshLabels(cycleBoundaries, selectedTWall)
    if (!hidePreview && !selectedTWall) {
      this.drawPreviewArrows()
    }
  }

  drawAuxiliaryGuidelines(): void {
    this.auxiliaryGuidelineLayer.removeChildren()
    this.auxiliaryGuidelines.forEach((guideline) => {
      this.auxiliaryGuidelineLayer.addChild(new AuxiliaryGuidelineItem(guideline))
    })
  }

  findLabelOfEdge(edge: Edge): LengthLabel | undefined {
    const centerPoint = edge.getCenterPoint()
    return this.labelService.findLengthLabelNearPoint(centerPoint, edge.thickness)
  }

  drawMesh(): void {
    const newpath = this.model.createPath()
    newpath.style = this.getMeshStyle()

    if (newpath !== this.path) {
      this.path.remove()
      this.layer.addChild(newpath)
      this.path = newpath
    }
  }

  private drawPreviewMesh(): void {
    if (this.previewModel != null) {
      const newpath = this.previewModel.createPath()
      newpath.style = PREVIEW_PATH_STYLE

      if (this.previewModel.isValid()) {
        newpath.style.strokeColor = PREVIEW_COLOR
      } else {
        newpath.style.strokeColor = DANGER_COLOR
      }

      if (newpath !== this.previewPath) {
        this.previewPath?.remove()
        this.previewLayer.addChild(newpath)
        this.previewPath = newpath
      }
    } else {
      this.previewPath?.remove()
      this.previewPath = undefined
    }
  }

  private drawPreviewArrows(): void {
    if (this.previewModel != null) {
      this.edgeSelectionService?.getSelectedEdges().forEach((it) => {
        const startsFromLeftOrBottom =
          (it.startsFromLeft && !it.isVertical) || (it.isVertical && it.startsFromBottom)

        const arrowPointToRight = startsFromLeftOrBottom ? it.startPoint : it.endPoint
        const arrowPointToLeft = startsFromLeftOrBottom ? it.endPoint : it.startPoint
        const vectorToLeft = arrowPointToLeft.subtract(arrowPointToRight)
        const vectorToRight = arrowPointToRight.subtract(arrowPointToLeft)
        const angle = it.calculateAngleFromOrigin(startsFromLeftOrBottom)

        switch (it.lengthTransformationDirection) {
          case TransformationLengthDirection.toRight:
            const movedRightArrowPoint = arrowPointToRight.add(vectorToLeft.normalize(15))

            this.labelService.drawPreviewRightArrowLabel(movedRightArrowPoint, angle)
            break
          case TransformationLengthDirection.toLeft:
            const movedLeftArrowPoint = arrowPointToLeft.add(vectorToRight.normalize(15))

            this.labelService.drawPreviewLeftArrowLabel(movedLeftArrowPoint, angle)
            break
          case TransformationLengthDirection.evenly:
            const centerPoint = it.getCenterPoint()
            const offsetBetweenArrows = 13

            this.labelService.drawPreviewLeftArrowLabel(
              centerPoint.add(vectorToRight.normalize(offsetBetweenArrows)),
              angle
            )
            this.labelService.drawPreviewRightArrowLabel(
              centerPoint.add(vectorToLeft.normalize(offsetBetweenArrows)),
              angle
            )
            break
        }

        if (it.thicknessTransformationDirection !== undefined) {
          if (this.previewModel && this.previewModel instanceof WallModel) {
            this.labelService.drawPreviewThicknessArrowLabels(
              it.getCenterPoint(),
              it.thickness,
              it.thicknessTransformationDirection,
              angle,
              vectorToLeft
            )
          }
        }
      })
    } else {
      this.edgeSelectionService?.getSelectedEdges().forEach((it) => {
        it.lengthTransformationDirection = undefined
        it.thicknessTransformationDirection = undefined
      })
    }
  }
}
