<ng-container
  *ngIf="
    this.onboardingSeriesKeysOfPage && this.onboardingSeriesKeysOfPage.length > 0;
    else resetAllModal
  ">
  <form [formGroup]="resetOnboardingTrackingForm" (ngSubmit)="onSubmit()">
    <fieldset class="radio-button-group">
      <div class="radio-button-group-content">
        <label class="items-start radio-button-container">
          <input
            class="radio-button mt-sm"
            type="radio"
            name="radioButtonGroup"
            [value]="this.resetOptions.RESET_ALL"
            formControlName="resetOptions" />
          <span class="radio-button-label text-wrap text-start">
            {{ 'ONBOARDING_RESET_WITH_OPTIONS.REACTIVATE_ALL' | translate }}
          </span>
        </label>
        <label class="items-start radio-button-container">
          <input
            class="radio-button mt-sm"
            type="radio"
            name="radioButtonGroup"
            [value]="resetOptions.RESET_SPEC"
            formControlName="resetOptions" />
          <span class="radio-button-label text-wrap text-start">
            {{ 'ONBOARDING_RESET_WITH_OPTIONS.REACTIVATE_PAGE' | translate }}
          </span>
        </label>
      </div>
    </fieldset>
  </form>
</ng-container>
<ng-template #resetAllModal>
  <div class="gap-1 d-flex button-wrapper form-container">
    {{ 'ONBOARDING_RESET_WITHOUT_OPTIONS.REACTIVATE_ALL' | translate }}
  </div>
</ng-template>
