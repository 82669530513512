<efp-sidebar-filter-section [title]="'STOCK.FILTER.ACCORDEON_OPT.HEADER' | translate">
  <ion-item-group>
    <button
      class="button button-secondary w-full justify-center"
      (click)="accordionFunctions.notifyExpandButtonClick()">
      {{ 'STOCK.FILTER.ACCORDEON_OPT.EXPAND_ALL' | translate }}
    </button>
  </ion-item-group>
  <ion-item-group>
    <button
      class="button button-secondary w-full justify-center"
      (click)="accordionFunctions.notifyCollapseButtonClick()">
      {{ 'STOCK.FILTER.ACCORDEON_OPT.COLLAPSE_ALL' | translate }}
    </button>
  </ion-item-group>
</efp-sidebar-filter-section>
