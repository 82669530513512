<form [formGroup]="dynamicForm" *ngIf="options && options.length > 0" (ngSubmit)="onSubmit()">
  <fieldset class="radio-button-group" *ngIf="radioFormOptions">
    <div class="flex flex-col radio-button-group-content gap-base">
      <label class="items-start radio-button-container" *ngFor="let item of radioFormOptions">
        <input
          class="radio-button mt-sm"
          type="radio"
          [value]="item.value"
          formControlName="formOptions" />
        <span class="radio-button-label text-wrap text-start">
          {{ item.label }}
        </span>
        <ng-container *ngIf="errors">
          <span class="radio-button-alert-message" *ngFor="let key of errors | keys">
            {{ errors[key] }}
          </span>
        </ng-container>
      </label>
    </div>
  </fieldset>

  <ng-container *ngIf="textFormOptions">
    <label class="text-field-container" *ngFor="let option of textFormOptions">
      <span class="text-field-label" *ngIf="option.label">{{ option.label | translate }}</span>
      <input
        type="option.type"
        class="text-field"
        [placeholder]="option.placeholder"
        formControlName="formOptions" />
      <ng-container *ngIf="errors">
        <span class="text-field-alert-message" *ngFor="let key of errors | keys">
          {{ errors[key] }}
        </span>
      </ng-container>
    </label>
  </ng-container>
</form>
