<efp-safe-area-top [backgroundColor]="topSafeAreaBackgroundColor"></efp-safe-area-top>
<ion-content>
  <ion-row>
    <ion-col size="3"></ion-col>
    <ion-col size="6">
      <ion-title class="ion-text-center">
        <div class="ion-text-wrap">
          <ng-container *ngIf="screens && screens.length !== 0; else singleTitle">
            {{ screens[currentIndex].name }}
          </ng-container>
        </div>
      </ion-title>
    </ion-col>
    <ion-col></ion-col>
    <ion-col size="auto">
      <button
        class="action-icon action-icon-large"
        (click)="closeModal()"
        data-test-selector="result-2d-viewer-screenshot-close">
        close
      </button>
    </ion-col>
  </ion-row>

  <swiper-container init="false" class="swiper" #swiperContainer>
    <ng-container *ngIf="screens && screens.length !== 0; else singleImg">
      <swiper-slide lazy="true" *ngFor="let screen of screens">
        <div class="swiper-zoom-container">
          <img [src]="isSlab ? getSafeUrl(screen.screenshot) : screen.screenshot" loading="lazy" />
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>

  <efp-measurement-legend
    [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
    [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
    [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
    [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
    [buildingType]="buildingType ?? undefined"
    [screenshotModalView]="true"
    [result]="true"
    [formwork]="planSettings ? planSettings.formworkWall : undefined"></efp-measurement-legend>

  <div class="swiper-nav" *ngIf="this.screens && this.screens.length !== 0">
    <button
      class="action-icon action-icon-large"
      [disabled]="currentIndex == 0"
      (click)="swiper?.slidePrev()"
      fill="clear">
      <span class="button-icon">chevron_backward</span>
    </button>
    <ion-ripple-effect></ion-ripple-effect>
    <ion-title class="ion-text-center">
      {{ currentIndex + 1 + ' / ' + screens.length }}
    </ion-title>
    <button
      class="action-icon action-icon-large"
      shape="round"
      [disabled]="currentIndex == screens.length - 1"
      (click)="swiper?.slideNext()"
      fill="clear">
      <span class="button-icon">chevron_forward</span>
    </button>
  </div>
</ion-content>

<!--cases for single screenshot modal-->
<ng-template #singleImg>
  <swiper-slide lazy="true" *ngIf="singleImgSrc">
    <div class="no-zoom-container d-flex">
      <img [src]="singleImgSrc" loading="lazy" />
    </div>
  </swiper-slide>
</ng-template>

<ng-template #singleTitle>
  {{ title }}
</ng-template>
<efp-safe-area-bottom></efp-safe-area-bottom>
