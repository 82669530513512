// URL to blob storage holding favourites article images
const FAVOURITE_IMAGE_URL =
  // eslint-disable-next-line max-len
  'https://azwestprodesdcdnapps.blob.core.windows.net/efpcont/live/FavouritesImages/{key}.png?sp=rl&st=2021-09-15T09:21:34Z&se=2025-01-01T18:21:34Z&spr=https&sv=2020-08-04&sr=c&sig=mZ%2By7AAFFsTnAm%2BJrMp9SZ7u1VqLUylAHC7hMHrq%2B5A%3D'

export function getFavImgUrl(key: string): string {
  return FAVOURITE_IMAGE_URL.replace('{key}', key)
}

const ACCESSORY_IMAGE_URL =
  // eslint-disable-next-line max-len
  'https://azwestprodesdcdnapps.blob.core.windows.net/efpcont/Previews/{key}.jpg?sp=rl&st=2021-09-15T09:21:34Z&se=2025-01-01T18:21:34Z&spr=https&sv=2020-08-04&sr=c&sig=mZ%2By7AAFFsTnAm%2BJrMp9SZ7u1VqLUylAHC7hMHrq%2B5A%3D'

export function getAccessoryImgUrl(key: string): string {
  return ACCESSORY_IMAGE_URL.replace('{key}', key)
}
