import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ThumbnailPreviewComponent } from '../thumbnail-preview/thumbnail-preview.component'
import { Capacitor } from '@capacitor/core'
import { ModalController } from '@ionic/angular'
import { ScreenshotModalComponent } from '../../../pages/result/components/result-2d-viewer/screenshot-modal/screenshot-modal.component'
import { PlanSettings } from '../../../models/planSettings'
import { PlanType } from '../../formwork-planner'
import { Screenshot } from '../../../models/screenshot'

@Component({
  selector: 'efp-thumbnail-action-item',
  templateUrl: './thumbnail-action-item.component.html',
  styleUrls: ['./thumbnail-action-item.component.scss'],
})
export class ThumbnailActionItemComponent implements OnInit {
  constructor(private readonly modalCtrl: ModalController) {}

  @Input() title?: string
  @Input() imgSrc?: string
  @Input() artNr?: string

  @Input() initialScreen?: Screenshot
  @Input() screens: Screenshot[] = []
  @Input() isSlab = false
  @Input() planSettings: PlanSettings | undefined
  @Input() buildingType: PlanType | undefined
  @Input() fallbackUrl = 'assets/icon/imageNotFound.svg'

  @Output() readonly requestFallbackImg = new EventEmitter<string>()

  public readonly componentType = ThumbnailPreviewComponent

  ngOnInit(): void {
    if (this.initialScreen) {
      this.imgSrc = this.initialScreen?.screenshot
      this.title = this.initialScreen?.name
    }
  }

  public async openImgDialog(event?: Event): Promise<void> {
    if (event) event.stopPropagation()
    const isNative = Capacitor.isNativePlatform()
    await this.modalCtrl
      .create({
        backdropDismiss: true,
        component: ScreenshotModalComponent,
        componentProps: {
          singleImgSrc: this.imgSrc,
          title: this.title,
          initialScreen: this.initialScreen,
          screens: this.screens,
          isSlab: this.isSlab,
          planSettings: this.planSettings,
          buildingType: this.buildingType,
        },
        cssClass: 'screenshot-modal',
        breakpoints: isNative ? [0, 0.98] : undefined,
        initialBreakpoint: isNative ? 0.98 : undefined,
      })
      .then(async (modal) => {
        await modal.present()
      })
  }

  onImageError(): void {
    this.requestFallbackImg.emit(this.artNr)
    this.imgSrc = this.fallbackUrl
  }
}
