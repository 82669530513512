<ng-container *ngIf="this.httpRequestSyncService.synchronizationState$ | async as syncState">
  <button
    class="action-icon action-icon-large"
    [disabled]="
      !history?.hasUndoActions() || syncState === SynchronizationState.SYNC_RUNNING || selectionOpen
    "
    efpTooltip
    [tooltipTitle]="'TOOLBAR.UNDO' | translate"
    (click)="undo.emit()"
    data-test-selector="planner-undo">
    undo
  </button>
  <button
    class="action-icon action-icon-large"
    [disabled]="
      !history?.hasRedoActions() || syncState === SynchronizationState.SYNC_RUNNING || selectionOpen
    "
    efpTooltip
    [tooltipTitle]="'TOOLBAR.REDO' | translate"
    (click)="redo.emit()"
    data-test-selector="planner-redo">
    redo
  </button>
</ng-container>
