<ng-container *ngIf="showSyncIndicator$ | async as showSyncIndicator">
  <div [ngSwitch]="httpRequestSyncService.synchronizationState$ | async" class="flex">
    <ng-container *ngSwitchCase="'SYNC_RUNNING'">
      <div class="sync-text">{{ 'SYNC.RUNNING' | translate }}</div>
      <ion-spinner class="sync-icon" name="circular"></ion-spinner>
    </ng-container>

    <ng-container *ngSwitchCase="'SYNC_SAVED'">
      <div class="headerbar-action-icon">cloud_done</div>
    </ng-container>

    <div *ngSwitchCase="'SYNC_FAILED'" class="state-error d-flex">
      <div class="sync-text sync-failed-icon">{{ 'SYNC.FAILED' | translate }}</div>
      <ion-icon class="sync-icon" src="/assets/icon/ic_warning_red.svg"></ion-icon>
    </div>
  </div>
</ng-container>
