import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { OnboardingRepository } from '../../../../repositories/onboarding.repository'
import { OnboardingHintSeriesKey } from '../../../../models/onboarding/onboarding-hint-series-key'
import { OnboardingService } from '../../../../services/onboarding.service'
import { ModalActionHandlingService } from '../../simple-modal/modal-action-handling.service'
import { ModalChildComponent } from '../../simple-modal/modal-child/modal-child.component'
import { ModalDismissAction } from '../../simple-modal/simple-modal.component'

export enum ResetOnboardingOptions {
  RESET_ALL = 'RESET_ALL',
  RESET_SPEC = 'RESET_SPEC',
}

@Component({
  selector: 'efp-reset-onboarding-modal',
  templateUrl: './reset-onboarding-modal.component.html',
})
export class ResetOnboardingModalComponent extends ModalChildComponent implements OnInit {
  @Input() onboardingSeriesKeysOfPage?: OnboardingHintSeriesKey[]

  constructor(
    private readonly onboardingRepository: OnboardingRepository,
    private readonly onboardingService: OnboardingService,
    protected readonly dynamicService: ModalActionHandlingService,
    protected readonly modalCtrl: ModalController
  ) {
    super(dynamicService, modalCtrl)
  }
  public resetOptions = ResetOnboardingOptions

  public resetOnboardingTrackingForm = new FormGroup({
    resetOptions: new FormControl(ResetOnboardingOptions.RESET_ALL, {
      validators: [Validators.required],
      nonNullable: true,
    }),
  })
  private seenOnboardingSeriesKeysOfPage?: OnboardingHintSeriesKey[]

  async ngOnInit(): Promise<void> {
    await this.findOnboardingKeysToDelete()
  }

  public async onSubmit(): Promise<void> {
    const value = this.resetOnboardingTrackingForm.value.resetOptions

    if (value === ResetOnboardingOptions.RESET_ALL) await this.resetAllOnboardingTrackings()
    else {
      if (this.seenOnboardingSeriesKeysOfPage) {
        await Promise.all(
          this.seenOnboardingSeriesKeysOfPage.map(async (key) => {
            await this.onboardingRepository.deleteSeriesByKey(key)
          })
        )
      }
      this.triggerOnboardingHintsOfPage()
      await this.closeModal(null, ModalDismissAction.CONFIRM)
    }
  }

  public async resetAllOnboardingTrackings(): Promise<void> {
    await this.onboardingRepository.deleteAllOnboardingTracking()
    this.triggerOnboardingHintsOfPage()
    await this.closeModal(null, ModalDismissAction.CONFIRM)
  }

  private triggerOnboardingHintsOfPage(): void {
    if (this.onboardingSeriesKeysOfPage) {
      this.onboardingSeriesKeysOfPage.map((key) => {
        this.onboardingService.trigger(key)
      })
    }
  }

  private async findOnboardingKeysToDelete(): Promise<void> {
    if (!this.onboardingSeriesKeysOfPage?.length) return

    const seenOnboardings = await this.onboardingRepository.getCurrentOnboardingTracking()
    this.seenOnboardingSeriesKeysOfPage = this.onboardingSeriesKeysOfPage.filter((key) =>
      seenOnboardings.includes(key)
    )
  }
}
