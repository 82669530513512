import { Injectable } from '@angular/core'
import { CycleBoundary, CycleSymbol } from 'formwork-planner-lib'
import { CycleBoundaryCreateCommandParam, CycleSymbolModel } from '../../../../generated/efp-api'
import { DataService } from '../../data.service'
import { CycleDao } from '../cycle.dao'

@Injectable()
export class CycleSqlDao extends CycleDao {
  constructor(private readonly dataService: DataService) {
    super()
  }

  async createCycleBoundary(line: CycleBoundaryCreateCommandParam): Promise<number> {
    const statement =
      'INSERT INTO CycleBoundary (planId, start_x, start_y, end_x, end_y, cycleNumberLeft, cycleNumberRight) VALUES (?,?,?,?,?,?,?)'
    const values = [
      line.planId,
      line.startx,
      line.starty,
      line.endx,
      line.endy,
      line.cycleNumberLeft,
      line.cycleNumberRight,
    ]
    const result = await this.dataService.executeStatement(statement, values)
    return result.insertId
  }

  async createCycleSymbol(symbol: CycleSymbolModel): Promise<void> {
    const statement = 'INSERT INTO CycleSymbol (planId, posX, posY, cycleNumber) VALUES (?,?,?,?)'
    const values = [symbol.planId, symbol.posX, symbol.posY, symbol.cycleNumber]
    await this.dataService.executeStatement(statement, values)
  }

  async updateCycleNumbers(lines: CycleBoundary[]): Promise<void> {
    for (const line of lines) {
      const update =
        'UPDATE CycleBoundary SET cycleNumberLeft = ?, cycleNumberRight = ? WHERE id = ?'
      const values = [line.cycleNumberLeft, line.cycleNumberRight, line.id]
      await this.dataService.executeStatement(update, values)
    }
  }

  async updateCycleBoundaryPosition(boundary: CycleBoundary): Promise<void> {
    const update =
      'UPDATE CycleBoundary SET start_x = ?, start_y = ?, end_x = ?, end_y = ? WHERE id = ?'
    const values = [boundary.start.x, boundary.start.y, boundary.end.x, boundary.end.y, boundary.id]
    await this.dataService.executeStatement(update, values)
  }

  async findAllCycleBoundariesByPlanId(planId: number): Promise<CycleBoundary[]> {
    const res = await this.dataService.executeStatement(
      'SELECT * FROM CycleBoundary WHERE planId = ?',
      [planId]
    )
    const cycleBoundaries: CycleBoundary[] = []
    for (let i = 0; i < res.rows.length; i++) {
      const row = res.rows.item(i)
      cycleBoundaries[i] = {
        id: row.id,
        start: { x: row.start_x, y: row.start_y },
        end: { x: row.end_x, y: row.end_y },
        cycleNumberLeft: row.cycleNumberLeft,
        cycleNumberRight: row.cycleNumberRight,
      }
    }
    return cycleBoundaries
  }

  async findAllCycleSymbolsByPlanId(planId: number): Promise<CycleSymbol[]> {
    const result = await this.dataService.executeStatement(
      'SELECT * FROM CycleSymbol WHERE planId  = ?',
      [planId]
    )
    const cycleSymbols: CycleSymbol[] = []
    for (let i = 0; i < result.rows.length; i++) {
      const row = result.rows.item(i)
      cycleSymbols[i] = {
        position: { x: row.posX, y: row.posY },
        cycleNumber: row.cycleNumber,
      }
    }
    return cycleSymbols
  }

  public async removeAllCycleBoundariesByPlanId(planId: number): Promise<void> {
    const statement = 'DELETE FROM CycleBoundary WHERE planId=?'
    const values = [planId]
    await this.dataService.executeStatement(statement, values)
    await this.removeAllSymbolsByPlanId(planId)
  }

  public async removeAllSymbolsByPlanId(planId: number): Promise<void> {
    const statement = 'DELETE FROM CycleSymbol WHERE planId=?'
    const values = [planId]
    await this.dataService.executeStatement(statement, values)
  }

  public async deleteCycleBoundary(id: number): Promise<void> {
    const statement = 'DELETE FROM CycleBoundary WHERE id = ?'
    const values = [id]
    await this.dataService.executeStatement(statement, values)
  }
}
