<div
  (click)="openImgDialog($event)"
  (keydown.enter)="openImgDialog()"
  role="button"
  tabindex="0"
  class="flex-1 img-container"
  efpHoverPortal
  [componentTemplate]="this.componentType"
  [inputsForComponentTemplate]="[
    { title: title },
    {
      imgSrc: imgSrc
    },
    { artNr: artNr }
  ]">
  <img #Image (error)="onImageError()" [src]="imgSrc" />
</div>
