<ng-container *efpShowOn="'mobile'">
  <ion-segment
    efpKeyboardShortcut
    (tabShortcut)="switchTab($event)"
    mode="md"
    [ngModel]="selectedTab"
    [disabled]="!valid"
    (ngModelChange)="updateSelectedTab($event)">
    <ion-segment-button tabindex="-1" [value]="measureType.outerLength" class="direction">
      {{ 'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.OUTER_LENGTH' | translate }}
    </ion-segment-button>
    <ion-segment-button
      tabindex="-1"
      *ngIf="plan.buildingType === 'WALL'"
      class="direction center-button"
      [value]="measureType.innerLength">
      {{ 'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.INNER_LENGTH' | translate }}
    </ion-segment-button>
    <ion-segment-button
      tabindex="-1"
      *ngIf="plan.buildingType === 'WALL'"
      [value]="measureType.width"
      class="direction center-button">
      {{ 'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.WIDTH' | translate }}
    </ion-segment-button>
    <ion-segment-button
      tabindex="-1"
      *ngIf="plan.buildingType === 'WALL'"
      [value]="measureType.height"
      class="direction">
      {{ 'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.HEIGHT' | translate }}
    </ion-segment-button>
  </ion-segment>

  <div
    *ngIf="isChangingEditableEdgeAttribute"
    [ngClass]="{
      'updating-keyboard-web': isWebversion,
      'updating-keyboard-mobile': !isWebversion && unit !== 'inch',
      'updating-keyboard-mobile-inch': !isWebversion && unit === 'inch'
    }"
    class="updating-keyboard"></div>

  <efp-keypad
    #efpKeypad
    *ngIf="selectedTab !== measureType.height && !isChangingEditableEdgeAttribute"
    [initialValue]="currentMeasure"
    (keypadChange)="updateMeasure($event)"
    [highlightError]="!valid"
    [disabled]="showLockedInError || showLockedThicknessError"
    [unit]="unit"
    [hasAdditionalContent]="showDirectionSegment">
    <ion-segment
      *ngIf="selectedTab !== measureType.width"
      class="segment-transformation-direction"
      mode="ios"
      swipe-gesture="true"
      [(ngModel)]="lengthDirection">
      <ion-segment-button [value]="transformationDirectionLength.toRight">
        <ion-icon src="assets/icon/length-to-right.svg"></ion-icon>
      </ion-segment-button>
      <ion-segment-button [value]="transformationDirectionLength.evenly">
        <ion-icon src="assets/icon/length-evenly.svg"></ion-icon>
      </ion-segment-button>
      <ion-segment-button [value]="transformationDirectionLength.toLeft">
        <ion-icon src="assets/icon/length-to-left.svg"></ion-icon>
      </ion-segment-button>
    </ion-segment>

    <ion-segment
      *ngIf="selectedTab === measureType.width"
      class="segment-transformation-direction"
      mode="ios"
      swipe-gesture="true"
      [(ngModel)]="thicknessDirection">
      <ion-segment-button
        [disabled]="!this.edgeHasOnlyRectangularNeighbors"
        [value]="transformationDirectionWidth.outsideIn"
        data-test-selector="planner-measurement-editor-outside-in">
        <ion-icon src="assets/icon/width-outside-in.svg"></ion-icon>
      </ion-segment-button>
      <ion-segment-button
        [value]="transformationDirectionWidth.evenly"
        data-test-selector="planner-measurement-evenly">
        <ion-icon src="assets/icon/width-evenly.svg"></ion-icon>
      </ion-segment-button>
      <ion-segment-button
        [disabled]="!this.edgeHasOnlyRectangularNeighbors"
        [value]="transformationDirectionWidth.insideOut"
        data-test-selector="planner-measurement-editor-inside-out">
        <ion-icon src="assets/icon/width-inside-out.svg"></ion-icon>
      </ion-segment-button>
    </ion-segment>

    <ion-note *ngIf="showLockedInError" color="danger">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.ERROR_NEIGHBOURS_LOCKED' | translate
      }}
    </ion-note>
    <ion-note *ngIf="showLockedThicknessError" color="danger">
      {{ 'PLAN.MEASURE.WALL.ERROR_THICKNESS_LOCKED' | translate }}
    </ion-note>

    <ion-note *ngIf="showLengthError" color="danger">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.LENGTH_ERROR'
          | translate
            : {
                minLength: convertCmValueToCurrentUnit(minLength),
                maxLength: convertCmValueToCurrentUnit(maxLength),
                unit: unit
              }
      }}
    </ion-note>
    <ion-note *ngIf="showThicknessError" color="danger">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.THICKNESS_ERROR'
          | translate
            : {
                minThickness: convertCmValueToCurrentUnit(minThickness),
                maxThickness: convertCmValueToCurrentUnit(maxThickness),
                unit: unit
              }
      }}
    </ion-note>

    <ion-note *ngIf="selectedTab === measureType.width && plan.buildingType === 'SLAB'">
      {{ 'PLAN.MEASURE.SLAB.WARNING_THICKNESS_CHANGE' | translate }}
    </ion-note>
  </efp-keypad>

  <div *ngIf="selectedTab === measureType.height && plan" class="linkButtonContainer">
    <ion-note>
      {{ 'PLAN.MEASURE.WARNING_HEIGHT_CHANGE' | translate }}
    </ion-note>
    <button fill="clear" class="actionButton" [routerLink]="['/drawingsettings', plan.id]">
      {{ 'PLAN.MEASURE.LINK_TITLE' | translate }}
      <ion-icon slot="end" src="assets/icon/settings.svg"></ion-icon>
    </button>
  </div>

  <efp-web-mobile-button-bar
    efpKeyboardShortcut
    (enterShortcut)="onSaveClicked()"
    (escShortcut)="cancel()"
    [applyChangesDisabled]="showLockedInError"
    (canceled)="cancel()"
    (applied)="onSaveClicked()">
  </efp-web-mobile-button-bar>
</ng-container>

<overlay-editor-container
  *efpShowOn="'web'"
  efpKeyboardShortcut
  (enterShortcut)="onSaveClicked()"
  (escShortcut)="cancel()"
  [formGroup]="measurementForm"
  [valid]="valid"
  [hasDifferentButtonAlignments]="showButton">
  <ng-container overlay-button *ngIf="showButton">
    <button
      *ngIf="selectedTab !== measureType.width"
      class="efp-circle-floating-button align-bottom light"
      [ngClass]="{
        light: lengthDirection != transformationDirectionLength.toRight,
        dark: lengthDirection == transformationDirectionLength.toRight
      }"
      (click)="lengthDirection = transformationDirectionLength.toRight"
      (mouseover)="lengthDirection = transformationDirectionLength.toRight"
      (mouseleave)="lengthDirection = prevLengthDirection">
      <ion-icon
        slot="icon-only"
        class="iconSize24"
        src="assets/icon/length-to-right.svg"></ion-icon>
    </button>
    <button
      *ngIf="selectedTab !== measureType.width"
      class="efp-circle-floating-button align-top dark"
      [ngClass]="{
        light: lengthDirection != transformationDirectionLength.evenly,
        dark: lengthDirection == transformationDirectionLength.evenly
      }"
      (click)="lengthDirection = transformationDirectionLength.evenly"
      (mouseover)="lengthDirection = transformationDirectionLength.evenly"
      (mouseleave)="lengthDirection = prevLengthDirection">
      <ion-icon slot="icon-only" class="iconSize24" src="assets/icon/length-evenly.svg"></ion-icon>
    </button>
    <button
      *ngIf="selectedTab !== measureType.width"
      class="efp-circle-floating-button align-bottom light"
      [ngClass]="{
        light: lengthDirection != transformationDirectionLength.toLeft,
        dark: lengthDirection == transformationDirectionLength.toLeft
      }"
      (click)="lengthDirection = transformationDirectionLength.toLeft"
      (mouseover)="lengthDirection = transformationDirectionLength.toLeft"
      (mouseleave)="lengthDirection = prevLengthDirection">
      <ion-icon slot="icon-only" class="iconSize24" src="assets/icon/length-to-left.svg"></ion-icon>
    </button>

    <button
      *ngIf="selectedTab === measureType.width"
      class="efp-circle-floating-button align-bottom light"
      [ngClass]="{
        light: thicknessDirection != transformationDirectionWidth.outsideIn,
        dark: thicknessDirection == transformationDirectionWidth.outsideIn
      }"
      (click)="thicknessDirection = transformationDirectionWidth.outsideIn"
      (mouseover)="thicknessDirection = transformationDirectionWidth.outsideIn"
      (mouseleave)="thicknessDirection = prevThicknessDirection">
      <ion-icon
        slot="icon-only"
        class="iconSize24"
        src="assets/icon/width-outside-in.svg"></ion-icon>
    </button>
    <button
      *ngIf="selectedTab === measureType.width"
      class="efp-circle-floating-button align-top dark"
      [ngClass]="{
        light: thicknessDirection != transformationDirectionWidth.evenly,
        dark: thicknessDirection == transformationDirectionWidth.evenly
      }"
      (click)="thicknessDirection = transformationDirectionWidth.evenly"
      (mouseover)="thicknessDirection = transformationDirectionWidth.evenly"
      (mouseleave)="thicknessDirection = prevThicknessDirection">
      <ion-icon slot="icon-only" class="iconSize24" src="assets/icon/width-evenly.svg"></ion-icon>
    </button>
    <button
      *ngIf="selectedTab === measureType.width"
      class="efp-circle-floating-button align-bottom light"
      [ngClass]="{
        light: thicknessDirection != transformationDirectionWidth.insideOut,
        dark: thicknessDirection == transformationDirectionWidth.insideOut
      }"
      (click)="thicknessDirection = transformationDirectionWidth.insideOut"
      (mouseover)="thicknessDirection = transformationDirectionWidth.insideOut"
      (mouseleave)="thicknessDirection = prevThicknessDirection">
      <ion-icon
        slot="icon-only"
        class="iconSize24"
        src="assets/icon/width-inside-out.svg"></ion-icon>
    </button>
  </ng-container>

  <efp-length-input
    #efpLengthInput
    overlay-input
    *ngIf="unit"
    formControlName="measurementInput"
    [unit]="unit"
    [showUnit]="false"
    [minWidth]="48"
    [textAlign]="'center'"
    [shouldResizeInput]="true">
  </efp-length-input>

  <div overlay-warning>
    <div *ngIf="showLockedInError">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.ERROR_NEIGHBOURS_LOCKED' | translate
      }}
    </div>
    <div *ngIf="showLockedThicknessError">
      {{ 'PLAN.MEASURE.WALL.ERROR_THICKNESS_LOCKED' | translate }}
    </div>

    <div *ngIf="showLengthError">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.LENGTH_ERROR'
          | translate
            : {
                minLength: convertCmValueToCurrentUnit(minLength),
                maxLength: convertCmValueToCurrentUnit(maxLength),
                unit: unit
              }
      }}
    </div>
    <div *ngIf="showThicknessError">
      {{
        'PLAN.MEASURE.' + plan.buildingType.toUpperCase() + '.THICKNESS_ERROR'
          | translate
            : {
                minThickness: convertCmValueToCurrentUnit(minThickness),
                maxThickness: convertCmValueToCurrentUnit(maxThickness),
                unit: unit
              }
      }}
    </div>
  </div>
</overlay-editor-container>
<ng-container
  *ngIf="showTWallOnboardingHint"
  [efpOnboardingTrigger]="repositionTWallOnboardingHintsId()"
  [triggeredBy]="TriggerType.COMPONENT_RENDERED">
  <efp-onboarding-hints [onboardingHintSeriesKey]="repositionTWallOnboardingHintsId()">
  </efp-onboarding-hints>
</ng-container>
