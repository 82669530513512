import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import localeEnGb from '@angular/common/locales/en-GB'
import localeEs from '@angular/common/locales/es'
import localeFr from '@angular/common/locales/fr'
import localeHu from '@angular/common/locales/hu'
import localeIt from '@angular/common/locales/it'
import localePl from '@angular/common/locales/pl'
import localeRu from '@angular/common/locales/ru'
import localeSk from '@angular/common/locales/sk'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx'
import { Device } from '@awesome-cordova-plugins/device/ngx'
import { HTTP } from '@awesome-cordova-plugins/http/ngx'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx'
import { Capacitor } from '@capacitor/core'
import { EfpApiModule } from '@efp/api'
import { IonicModule, Platform } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import { Screenshot } from 'awesome-cordova-plugins-screenshot/ngx'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CatalogModule } from './pages/catalog/catalog.module'
import { AuthGuard } from './shared/guards/auth.guard'
import { DaoModule } from './services/dao/dao.module'
import { DataService } from './services/data.service'
import { ErrorHandlerService } from './services/error-handler.service'
import { FileService } from './services/file.service'
import { BrowserFileService } from './services/file/browser-file.service'
import { NativeFileService } from './services/file/native-file.service'
import { HttpCancelService } from './services/http-cancel.service'
import { defaultLanguage } from './services/translation.service'
import { HttpCancelRequestInterceptor } from './shared/interceptors/http-cancel-request.interceptor'
import { HttpLoadingRequestInterceptor } from './shared/interceptors/http-loading-request.interceptor'
import { HttpUnauthorizedRequestInterceptor } from './shared/interceptors/http-unauthorized-request.interceptor'
import { PreviousRouteService } from './services/previous-route.service'
import { CheckAppVersionInterceptor } from './shared/interceptors/http-check-app-version-request-interceptor'
import { AddLocalCacheIdHeaderInterceptor } from './shared/interceptors/http-add-local-cache-id-header.interceptor'

/* eslint-disable efp/no-dao-import */
// remove after native migration
import { PlanSqlDao } from './services/dao/sql/plan.sql-dao'
import { StockSqlDao } from './services/dao/sql/stock.sql-dao'
import { FavouriteSqlDao } from './services/dao/sql/favourite.sql-dao'
import { PlanResultResetCalculationSqlDao } from './services/dao/sql/plan-result-reset-calculation.sql-dao'
import { PlanResultSqlDao } from './services/dao/sql/plan-result.sql-dao'
import { ScreenshotSqlDao } from './services/dao/sql/screenshot.sql-dao'
import { ChangedResultPartSqlDao } from './services/dao/sql/changed-result-part.sql-dao'
import { PlanAccessoryLineSqlDao } from './services/dao/sql/plan-accessory-line.sql-dao'
import { PlanOutlineSqlDao } from './services/dao/sql/plan-outline.sql-dao'
import { CycleSqlDao } from './services/dao/sql/cycle.sql-dao'
import { ArticleSqlDao } from './services/dao/sql/article.sql-dao'
import { ProjectSqlDao } from './services/dao/sql/project.sql-dao'
import { PlanVisibilitySettingsSqlDao } from './services/dao/sql/plan-visibility-settings.sql-dao'
import { PlanSettingsSqlDao } from './services/dao/sql/plan-settings.sql-dao'
import { SimpleModalModule } from './shared/components/simple-modal/simple-modal.module'

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json')

export const initDatabaseAndMigrations =
  (platform: Platform, dataService: DataService): (() => Promise<void>) =>
  async () => {
    await platform.ready()
    if (Capacitor.isNativePlatform()) {
      await dataService.createDatabase()
    }
  }

export const createNativeHttpClient = (): HTTP => {
  const http = new HTTP()
  const calculationTimeout = 3 * 60 // Increase request timeout to 3 Minutes due to calculation
  http.setRequestTimeout(calculationTimeout)

  return http
}

export const daosForMigration = [
  FavouriteSqlDao,
  PlanSettingsSqlDao,
  PlanVisibilitySettingsSqlDao,
  PlanSqlDao,
  ProjectSqlDao,
  ArticleSqlDao,
  StockSqlDao,
  CycleSqlDao,
  PlanOutlineSqlDao,
  PlanAccessoryLineSqlDao,
  ChangedResultPartSqlDao,
  ScreenshotSqlDao,
  PlanResultSqlDao,
  PlanResultResetCalculationSqlDao,
  ScreenshotSqlDao,
]

registerLocaleData(localeEnGb, 'en-gb')
registerLocaleData(localeDe, 'de')
registerLocaleData(localeEs, 'es')
registerLocaleData(localeFr, 'fr')
registerLocaleData(localeHu, 'hu')
registerLocaleData(localeIt, 'it')
registerLocaleData(localePl, 'pl')
registerLocaleData(localeRu, 'ru')
registerLocaleData(localeSk, 'sk')
registerLocaleData(localeSk, 'no')

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage,
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.backendUrl],
        sendAccessToken: true,
      },
    }),
    EfpApiModule.forRoot({
      rootUrl: environment.backendUrl,
    }),
    DaoModule,
    SimpleModalModule,
    CatalogModule,
  ],
  providers: [
    SQLite,
    { provide: HTTP, useFactory: createNativeHttpClient },
    Screenshot,
    Device,
    AppVersion,
    SocialSharing,
    {
      provide: APP_INITIALIZER,
      useFactory: initDatabaseAndMigrations,
      deps: [Platform, DataService, PreviousRouteService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: FileService,
      useClass: Capacitor.isNativePlatform() ? NativeFileService : BrowserFileService,
    },
    HttpCancelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpUnauthorizedRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckAppVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddLocalCacheIdHeaderInterceptor,
      multi: true,
    },
    AuthGuard,
    { provide: OAuthStorage, useValue: localStorage },
    daosForMigration,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
