<ion-item class="d-flex-col preview-card p-4" lines="none">
  <div class="d-flex d-flex-col gap-3">
    <div class="title">{{ title }}</div>
    <div *ngIf="artNr" class="d-flex gap-2">
      <span class="grey">{{ 'GENERAL.ARTICLE_NUMBER' | translate }}:</span>
      <div class="basis-text">{{ artNr }}</div>
    </div>
    <div class="img-wrapper d-flex">
      <img [src]="imgSrc" #Image (error)="Image.src = 'assets/icon/imageNotFound.svg'" />
    </div>
  </div>
</ion-item>
