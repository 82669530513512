import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

@Injectable()
export class ModalActionHandlingService {
  private dismissAction = new Subject<boolean>()
  private disabled = new BehaviorSubject<boolean>(false)

  constructor() {}

  public getSubmission(): Observable<boolean> {
    return this.dismissAction.asObservable()
  }

  public submit(): void {
    this.dismissAction.next(true)
  }

  public close(): void {
    this.dismissAction.next(false)
  }

  public disableApplyButton(value: boolean): void {
    this.disabled.next(value)
  }

  public isApplyButtonDisabled(): Observable<boolean> {
    return this.disabled.asObservable()
  }
}
