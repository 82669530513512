import { Injectable } from '@angular/core'
import { UserDao } from '../services/dao/user.dao'
import { UserInfo } from 'angular-oauth2-oidc'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  constructor(private readonly userDao: UserDao) {}

  public userInfo$ = new BehaviorSubject<UserInfo | null>(null)

  public async registerUser(): Promise<number> {
    return this.userDao.registerUser()
  }

  public updateUserInfo(userInfo: UserInfo | null): void {
    this.userInfo$.next(userInfo)
  }
}
