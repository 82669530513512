import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'
import { ModalActionHandlingService } from '../simple-modal/modal-action-handling.service'
import { ModalChildComponent } from '../simple-modal/modal-child/modal-child.component'
import { ModalDismissAction } from '../simple-modal/simple-modal.component'
import { Translation } from '../../../services/translation.service'

export interface DynamicInput {
  type: string
  label?: string
  value: string
  placeholder?: string
}

@Component({
  selector: 'efp-dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent extends ModalChildComponent implements OnInit {
  @Input() options?: DynamicInput[]

  constructor(
    protected readonly dynamicService: ModalActionHandlingService,
    protected readonly modalCtrl: ModalController,
    private readonly translation: Translation
  ) {
    super(dynamicService, modalCtrl)
  }

  @Input() validationFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value
      ? null
      : { required: this.translation.translate('ERROR.FORMS.EMPTY_INPUT') }
  }

  public dynamicForm: FormGroup = new FormGroup({
    formOptions: new FormControl('', {
      validators: this.validationFn,
      nonNullable: true,
    }),
  })

  public errors: ValidationErrors | null = null

  public get textFormOptions(): DynamicInput[] | undefined {
    return this.options?.filter((option) => option.type === 'text')
  }

  public get radioFormOptions(): DynamicInput[] | undefined {
    return this.options?.filter((option) => option.type === 'radio')
  }

  ngOnInit(): void {
    if (this.options && this.options.length > 0) {
      const defaultValue = this.options[0].value
      this.dynamicForm.controls.formOptions.setValue(defaultValue)
      this.errors = this.checkIfFormValid()

      this.dynamicForm.controls.formOptions.valueChanges.subscribe(() => {
        this.errors = this.checkIfFormValid()
      })
    }
  }

  public async onSubmit(): Promise<void> {
    console.warn(this.dynamicForm.controls.formOptions.value, 'submit')
    await this.modalCtrl.dismiss(
      this.dynamicForm?.controls.formOptions.value,
      ModalDismissAction.CONFIRM
    )
  }

  private checkIfFormValid(): ValidationErrors | null {
    if (this.dynamicForm) {
      const errors = this.validationFn(this.dynamicForm.controls.formOptions)
      const hasErrors = errors && Object.keys(errors).length > 0
      this.dynamicService.disableApplyButton(!!hasErrors)
      return errors
    }
    return null
  }
}
