import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ResetOnboardingModalComponent } from '../reset-onboarding-modal.component'
import { SimpleModalComponent } from '../../../simple-modal/simple-modal.component'
import { OnboardingService } from '../../../../../services/onboarding.service'
import { Router } from '@angular/router'

@Component({
  selector: 'efp-reset-onboarding-button',
  templateUrl: './reset-onboarding-button.component.html',
  styleUrls: ['./reset-onboarding-button.component.scss'],
})
export class ResetOnboardingButtonComponent {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router
  ) {}

  private pageKey = this.router.url.split('/')[1]

  public async open(): Promise<void> {
    const onboardingSeriesKeysOfPage = await this.onboardingService.getOnboardingSeriesKeysForPage(
      this.pageKey
    )

    let title: string
    let confirmButtonLabel: string
    let cancelButtonLabel: string

    if (onboardingSeriesKeysOfPage.length > 0) {
      title = 'ONBOARDING_RESET_WITH_OPTIONS.TITLE'
      confirmButtonLabel = 'ONBOARDING_RESET_WITH_OPTIONS.APPLY'
      cancelButtonLabel = 'GENERAL.CANCEL'
    } else {
      title = 'ONBOARDING_RESET_WITHOUT_OPTIONS.TITLE'
      confirmButtonLabel = 'ONBOARDING_RESET_WITHOUT_OPTIONS.CONFIRM'
      cancelButtonLabel = 'ONBOARDING_RESET_WITHOUT_OPTIONS.CANCEL'
    }

    const modal = await this.modalCtrl.create({
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'modal-responsive-max-w-400',
      componentProps: {
        dynamicComponent: ResetOnboardingModalComponent,
        title,
        cancelButtonLabel,
        confirmButtonLabel,
        dynamicComponentProps: { onboardingSeriesKeysOfPage },
      },
    })
    await modal.present()
  }
}
