import { Injectable } from '@angular/core'
import { FavouriteProfile } from '../models/favourites'
import { FavouriteUsingObjects, FavouritesService } from './favourites.service'
import { Translation } from './translation.service'
import { ModalController } from '@ionic/angular'
import { PlanRepository } from '../repositories/plan.repository'
import { PlanSettingsRepository } from '../repositories/plan-settings.repository'
import { PlanSettingsService } from './plan-settings.service'
import { PlanResultRepository } from '../repositories/plan-result.repository'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router'
import { createStore } from '@ngneat/elf'
import { deleteAllEntities, getEntity, withEntities } from '@ngneat/elf-entities'
import { filter, pairwise } from 'rxjs'
import {
  ModalDismissAction,
  SimpleModalComponent,
} from '../shared/components/simple-modal/simple-modal.component'
import { DynamicFormComponent } from '../shared/components/dynamic-form/dynamic-form.component'

const changeAcceptedFavoriteStore = createStore(
  { name: 'changeAcceptedFavorites' },
  withEntities<FavouriteProfile>()
)

export enum EditWarningResult {
  cancel,
  editExisting,
  duplicateAndEdit,
}

@Injectable({
  providedIn: 'root',
})
export class FavouriteChangeAlertService {
  constructor(
    private readonly planRepository: PlanRepository,
    private readonly plansettingsRepo: PlanSettingsRepository,
    private readonly plansettingsService: PlanSettingsService,
    private readonly favouritesService: FavouritesService,
    private readonly planResultRepository: PlanResultRepository,
    private readonly translate: Translation,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalCtrl: ModalController
  ) {
    this.router.events
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const previousUrl = events[0].urlAfterRedirects
        const currentUrl = events[1].urlAfterRedirects

        const previousIsPlanSettings = previousUrl.startsWith('/plansettings/')
        const currentIsPlanSettings = currentUrl.startsWith('/plansettings/')
        if (previousIsPlanSettings && currentIsPlanSettings) {
          return
        }

        // reset all changeAcceptedFavourites when navigating from a favourite profile to a different page
        // (except when navigating to a the blacklist page)
        const previousIsFavProfile =
          previousUrl.startsWith('/favourites-overview/favourites/') &&
          !previousUrl.includes('blacklist')
        const currentIsNotBlacklist = !currentUrl.includes('blacklist')
        if (previousIsFavProfile && currentIsNotBlacklist) {
          void this.resetAllChangeAcceptedFavourites()
        }

        // reset all changeAcceptedFavourites when navigating from a plan settings page to a different page
        // (except when navigating to a the blacklist page
        if (previousIsPlanSettings && currentIsNotBlacklist) {
          void this.resetAllChangeAcceptedFavourites()
        }
      })
  }

  public async navToDuplicatedFavourite(profileID: number, parentRoute: string[]): Promise<void> {
    const favIdIndex = parentRoute.indexOf('favourites') + 1
    parentRoute[favIdIndex] = '' + profileID

    const newRoute = parentRoute.join('/')

    await this.router.navigate([newRoute], {
      relativeTo: this.route.parent,
      replaceUrl: true,
    })
  }

  public async changeFavProfileOfPlan(planId: number, newProfile: FavouriteProfile): Promise<void> {
    const plan = await this.planRepository.findOne(planId)
    if (plan) {
      const currPlanSettings = await this.plansettingsRepo.findOneById(plan.settingsId)
      if (currPlanSettings) {
        const newPlanSettings = currPlanSettings
        if (currPlanSettings?.slabFavId) {
          newPlanSettings.slabFavId = newProfile.id
        } else {
          newPlanSettings.wallFavId = newProfile.id
        }
        await this.plansettingsService.update(newPlanSettings)
      }
    }
  }

  public async getResultOfEditWarning(
    favourite?: FavouriteProfile,
    planId?: number
  ): Promise<EditWarningResult> {
    if (!favourite) {
      return EditWarningResult.cancel
    }

    const favoriteInChangeStore = changeAcceptedFavoriteStore.query(getEntity(favourite.id))
    if (favoriteInChangeStore) {
      return EditWarningResult.editExisting
    }

    const usedIn = await this.favouritesService.getFavouriteUsingObjects(favourite)

    if (planId) {
      // Prevent showing a alert and reset the calculation for the plan, if it's the only one which is using the current favorite profile
      if (usedIn.plans.length === 1 && usedIn.plans[0].id === planId) {
        void this.planResultRepository.resetCalculation(planId)
        return EditWarningResult.editExisting
      }
    }

    if (usedIn.plans.length > 0 || usedIn.projects.length > 1) {
      // context: Editing default plan settings or project plan settings and the favourite profile is used in a plan or another project
      const shouldEdit = await this.showEditWarningAndGetAnswer(usedIn, favourite)

      if (shouldEdit === EditWarningResult.editExisting) {
        usedIn.plans.forEach((plan) => {
          void this.planResultRepository.resetCalculation(plan.id)
        })
      }
      return shouldEdit
    } else {
      return EditWarningResult.editExisting
    }
  }

  public async showEditWarningAndGetAnswer(
    objectsUsingTheProfile: FavouriteUsingObjects,
    favourite: FavouriteProfile
  ): Promise<EditWarningResult> {
    const favoriteInChangeStore = changeAcceptedFavoriteStore.query(getEntity(favourite.id))
    if (favoriteInChangeStore) {
      return EditWarningResult.editExisting
    }

    const modal = await this.modalCtrl.create({
      component: SimpleModalComponent,
      backdropDismiss: true,
      cssClass: 'modal-responsive-max-w-400',
      componentProps: {
        dynamicComponent: DynamicFormComponent,
        title: 'IMPORT.FORMWORK_PROFILE.NAME_SELECTION_TITLE',
        innerHTML: `<p>${this.translate.translate('FAVOURITES.CHANGE_MESSAGE_PRETEXT')}</p>
              ${objectsUsingTheProfile.alertMessageObjectList}
              <p>${this.translate.translate('FAVOURITES.CHANGE_MESSAGE_ENDTEXT')}</p> `,
        criticalActionButtonLabel: 'FAVOURITES.CHANGE_BUTTON_EDIT',
        confirmButtonLabel: 'FAVOURITES.CHANGE_BUTTON_DUPLICATE',
      },
    })

    await modal.present()
    const { role } = await modal.onDidDismiss()

    switch (role) {
      case ModalDismissAction.CONFIRM:
        return EditWarningResult.duplicateAndEdit
      case ModalDismissAction.CRITICAL_ACTION:
        return EditWarningResult.editExisting
      case ModalDismissAction.CANCEL:
        return EditWarningResult.cancel
      default:
        return EditWarningResult.cancel
    }
  }

  private async resetAllChangeAcceptedFavourites(): Promise<void> {
    changeAcceptedFavoriteStore.update(deleteAllEntities())
  }
}
