import { CycleBoundaryDrawable, Edge, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { TRANSPARENT, WALL_COLOR, WALL_STROKE_COLOR } from '../../../constants/colors'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { WallModel } from '../model/WallModel'
import { RenderService } from './render.service'
import { EdgeSelectionService } from './edge-selection.service'
import { AuxiliaryGuideline } from '../model/guidelines/AuxiliaryGuideline'
import { Line2D } from '../../../utils/geometry/Line2D'
import { AuxiliaryGuidelineItem } from '../model/paper/AuxiliaryGuidelineItem'

const DEFAULT_WALL_STYLE = {
  strokeColor: WALL_STROKE_COLOR,
  strokeWidth: 1,
  dashArray: [] as number[],
  fillRule: 'evenodd',
  fillColor: WALL_COLOR,
} as paper.Style

const ACCESSORY_WALL_STYLE = {
  strokeColor: TRANSPARENT, // the color will be transparent because the accessory line styling happens on the accessory layer
  strokeWidth: 2,
  dashArray: [] as number[],
  fillRule: 'evenodd',
  fillColor: WALL_COLOR,
} as paper.Style

export class WallRenderService extends RenderService<WallModel> {
  public constructor(
    model: WallModel,
    paperScope: paper.PaperScope,
    planVisibilitySettings: PlanVisibilitySettings,
    edgeSelectionService?: EdgeSelectionService,
    public accessoryPageStyling = false
  ) {
    super(model, paperScope, PlanType.WALL, planVisibilitySettings, edgeSelectionService)
  }

  protected getMeshStyle(): paper.Style {
    if (this.accessoryPageStyling) {
      return ACCESSORY_WALL_STYLE
    } else {
      return DEFAULT_WALL_STYLE
    }
  }

  /**
   * Generates labels for the outline path of the model
   * @param cycleBoundaries if given, edges with cycle boundaries on them will be split and labels for each cycle segment will be generated
   * @param selectedTWall if given, the labels of the t-wall will be drawn with arrows to show that the t-wall can be moved
   */
  protected refreshLabels(cycleBoundaries?: CycleBoundaryDrawable[], selectedTWall?: Edge): void {
    const path = this.previewPath ?? this.path
    if (!path.children) {
      return
    }

    path.children.forEach((childPath) => {
      this.labelService.generateAngleLabelsForPath(childPath, false)
      this.labelService.generateLengthLabelsForPath(
        childPath,
        true,
        this.model.drawSetting.measurementUnit,
        cycleBoundaries,
        selectedTWall
      )
    })

    const model = this.previewModel ?? this.model
    this.labelService.generateWidthLabelsForConnectedWalls(
      model.getConnectingAndCloseDisconnectedMeshPoints(),
      model.drawSetting.measurementUnit
    )
  }

  /**
   * this code is used for debugging purposes. It visualizes the current snapping line
   * of a selected length label/cycle boundary
   * @param line
   */
  drawSnappingLine(line: paper.Path.Line): void {
    const guideLine: AuxiliaryGuideline = {
      line: new Line2D(line.firstCurve.point1, line.firstCurve.point2),
      crossingPoints: [],
    }

    this.auxiliaryGuidelineLayer.addChild(new AuxiliaryGuidelineItem(guideLine))
    this.auxiliaryGuidelineLayer.bringToFront()
  }
}
