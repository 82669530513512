<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <button (click)="closeModal()" class="action-icon action-icon-large">close</button>
    </ion-buttons>
    <ion-title size="large"> {{ slides[index].title }} </ion-title>
  </ion-toolbar>
</ion-header>
<efp-modal-form>
  <div class="modal-inner d-flex d-flex-col">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div *ngIf="this.index === i" [innerHTML]="slide.content"></div>
    </ng-container>
  </div>
</efp-modal-form>

<efp-steps-modal-footer>
  <button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="action-icon action-icon-large">
    chevron_backward
  </button>

  <ng-container status-indicators *ngIf="this.slides.length > 1">
    <ng-container *ngFor="let slide of slides; let i = index">
      <ion-icon
        (click)="goToSlide(i)"
        data-test-selector="feedback-modal-hint-status-indicator"
        aria-hidden="true"
        src="assets/icon/ellipse.svg"
        [ngClass]="{ active: index === i }"></ion-icon>
    </ng-container>
  </ng-container>

  <ng-container button-end>
    <button
      slot="end"
      *ngIf="index < this.slides.length - 1; else lastSlide"
      (click)="goToSlide(this.index + 1)"
      class="action-icon action-icon-large"
      data-test-selector="feedback-modal-next-slide">
      chevron_forward
    </button>
    <ng-template #lastSlide>
      <button
        (click)="closeModal()"
        class="action-icon action-icon-large"
        data-test-selector="onboarding-modal-next-hint">
        check
      </button>
    </ng-template>
  </ng-container>
</efp-steps-modal-footer>
