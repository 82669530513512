<div class="d-flex p-1 ion-justify-content-between">
  <button
    tabindex="-1"
    class="button button-secondary"
    (click)="cancel()"
    data-test-selector="bottom-menu-cancel">
    {{ 'GENERAL.CANCEL' | translate }}
  </button>
  <button
    tabindex="-1"
    class="button"
    (click)="applyChanges()"
    [disabled]="applyChangesDisabled"
    data-test-selector="bottom-menu-apply">
    {{ 'GENERAL.APPLY' | translate }}
  </button>
</div>
