<ion-grid>
  <ion-row>
    <ion-col size="{{ hasAdditionalContent ? 8 : 12 }}">
      <div class="keypad-input d-flex ion-justify-content-between" [class.error]="highlightError">
        <span>
          <span *efpShowOn="'mobile'" class="mobile-keyboard-input">
            <span [class.selected]="isInitialValue">{{ value }}</span
            >&nbsp;{{ unit === 'inch' ? '' : unit }}
          </span>
          <form *efpShowOn="'web'" class="web-keyboard-input" [formGroup]="keypadWebForm">
            <efp-length-input
              *ngIf="unit && unit !== '°'"
              [unit]="unit"
              #efpKeyboardInput
              formControlName="keyboardInput">
            </efp-length-input>
            <efp-angle-input
              *ngIf="unit && unit === '°'"
              #efpKeyboardInput
              formControlName="keyboardInput">
            </efp-angle-input>
          </form>
        </span>
        <span>
          <ion-icon
            class="reset-button"
            src="assets/icon/undo.svg"
            (click)="reset()"
            data-test-selector="keypad-reset"></ion-icon>
        </span>
      </div>
    </ion-col>
    <ion-col *ngIf="hasAdditionalContent" size="4">
      <ng-content></ng-content>
    </ion-col>
    <ion-col size="12">
      <ng-content select="ion-note"></ng-content>
    </ion-col>
  </ion-row>
  <ion-row *efpShowOn="'mobile'">
    <div class="grid grid-cols-3 gap-1 w-full pt-1">
      <div *ngFor="let value of keypadButtons" class="flex flex-1">
        <button
          class="button button-secondary flex flex-1 justify-center"
          (click)="append(value)"
          [disabled]="disabled"
          attr.data-test-selector="keypad-input-{{ value }}">
          {{ value }}
          <ion-icon *ngIf="value === ' '" src="assets/icon/space_bar.svg"></ion-icon>
        </button>
      </div>
      <div class="flex flex-1">
        <button
          class="action-icon action-icon-large flex flex-1 justify-center"
          (click)="backspace()"
          [disabled]="disabled"
          data-test-selector="keypad-backspace">
          backspace
        </button>
      </div>
    </div>
  </ion-row>
</ion-grid>
