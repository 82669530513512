import { ModalActionHandlingService } from '../modal-action-handling.service'
import { ModalController } from '@ionic/angular'
import { ModalDismissAction } from '../simple-modal.component'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DestroyRef, inject } from '@angular/core'

export abstract class ModalChildComponent {
  private readonly destroyRef = inject(DestroyRef)
  constructor(
    protected readonly dynamicService: ModalActionHandlingService,
    protected readonly modalCtrl: ModalController
  ) {
    this.dynamicService
      .getSubmission()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (data) {
          void this.onSubmit()
        } else {
          void this.closeModal()
        }
      })
  }

  protected async onSubmit(): Promise<void> {
    await this.modalCtrl.dismiss(ModalDismissAction.CONFIRM)
  }

  protected async closeModal(
    data?: unknown,
    action: ModalDismissAction = ModalDismissAction.CANCEL
  ): Promise<void> {
    await this.modalCtrl.dismiss(data, action)
  }
}
