import { Component, Input, OnInit } from '@angular/core'
import { FavouriteProfile } from '../../../models/favourites'
import { FavouritesService } from '../../../services/favourites.service'
import { ActionSheetButton, ActionSheetController, ModalController } from '@ionic/angular'
import { Translation } from '../../../services/translation.service'
import { Capacitor } from '@capacitor/core'
import { PlanSettingsService } from '../../../services/plan-settings.service'
import { Router } from '@angular/router'
import { PlanRepository } from '../../../repositories/plan.repository'
import { FAVOURITES_VERSION, OLD_FAVOURITES_VERSION } from '../../../constants/versions'
import { ModalStyle } from '../../../models/modalStyle'
import { SimpleModalComponent } from '../simple-modal/simple-modal.component'

@Component({
  selector: 'efp-favourites-overview-list-item',
  templateUrl: './favourites-overview-list-item.component.html',
})
export class FavouritesOverviewListItemComponent implements OnInit {
  @Input() favourite!: FavouriteProfile
  public linkedObjectsAmount?: number

  get formworkVersion(): string {
    return this.favourite.formworkVersion ?? OLD_FAVOURITES_VERSION
  }

  get isOutdated(): boolean {
    return this.formworkVersion !== FAVOURITES_VERSION
  }

  constructor(
    public readonly favouritesService: FavouritesService,
    public readonly actionSheetController: ActionSheetController,
    private readonly translate: Translation,
    private readonly planSettingsService: PlanSettingsService,
    private readonly router: Router,
    private readonly planRepository: PlanRepository,
    private readonly modalCtrl: ModalController
  ) {}

  async ngOnInit(): Promise<void> {
    const linkedObjects = await this.planRepository.findAllByFavouriteId(this.favourite.id)
    this.linkedObjectsAmount = linkedObjects.length
  }

  async presentFavouriteActionSheet(
    favourite: FavouriteProfile,
    event?: MouseEvent
  ): Promise<void> {
    if (event) {
      event.stopPropagation()
    }
    const buttons: ActionSheetButton[] = []

    if (!favourite.isStandard) {
      buttons.push({
        text: this.translate.translate('GENERAL.RENAME'),
        icon: 'pencil',
        handler: () => {
          void this.renameFavourite(favourite)
        },
      })
    }

    buttons.push({
      text: this.translate.translate('GENERAL.DUPLICATE'),
      icon: 'copy',
      handler: () => {
        void this.favouritesService.duplicateFavourite(favourite)
      },
    })

    buttons.push({
      text: this.translate.translate('FAVOURITES.SHARE_PROFILE'),
      icon: 'share-social-outline',
      handler: () => {
        void this.favouritesService.shareFavourite(favourite)
      },
    })

    buttons.push({
      text: this.translate.translate('FAVOURITES.SHARE_XML'),
      icon: 'share-social-outline',
      handler: () => {
        void this.favouritesService.shareTiposXML(favourite)
      },
    })

    if (JSON.stringify(favourite.values) !== JSON.stringify(favourite.basis.values)) {
      buttons.push({
        text: this.translate.translate('GENERAL.RESET'),
        role: 'destructive',
        icon: 'refresh',
        handler: () => {
          void this.resetFavourite(favourite)
        },
      })
    }

    if (!favourite.isStandard && (await this.isSelectedFavourite(favourite)) === false) {
      buttons.push({
        text: this.translate.translate('GENERAL.DELETE'),
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          void this.showDeleteWarningOrDelete(favourite)
        },
      })
    }

    buttons.push({
      text: this.translate.translate('GENERAL.CANCEL'),
      icon: 'close',
      role: 'cancel',
    })

    const actionSheet = await this.actionSheetController.create({
      mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md',
      buttons,
    })
    await actionSheet.present()
    await actionSheet.onDidDismiss()
  }

  private async renameFavourite(favouriteProfile: FavouriteProfile): Promise<void> {
    const favouriteName = await this.favouritesService.presentRenameDialog(favouriteProfile.name)
    if (favouriteName && favouriteName !== favouriteProfile.name) {
      favouriteProfile.name = favouriteName
      await this.favouritesService.updateFavourite(favouriteProfile)
    }
  }

  private async resetFavourite(favourite: FavouriteProfile): Promise<void> {
    await this.favouritesService.resetFavourite(favourite)
  }

  private async isSelectedFavourite(favourite: FavouriteProfile): Promise<boolean> {
    const defaultPlanSettings =
      await this.planSettingsService.getDefaultProjectPlanSettingsAndSetLastUnit()

    return (
      defaultPlanSettings?.slabFavId === favourite.id ||
      defaultPlanSettings?.wallFavId === favourite.id
    )
  }

  private async showDeleteWarningOrDelete(favourite: FavouriteProfile): Promise<void> {
    const objectsUsingTheProfile = await this.favouritesService.getFavouriteUsingObjects(favourite)

    if (
      objectsUsingTheProfile.plans.length > 0 ||
      objectsUsingTheProfile.projects.length > 0 ||
      objectsUsingTheProfile.isUsedByAppSettings
    ) {
      const modal = await this.modalCtrl.create({
        component: SimpleModalComponent,
        cssClass: 'modal-responsive-max-w-400',
        componentProps: {
          title: 'FAVOURITES.DELETE_HEADER',
          innerHTML: `
          <p>${this.translate.translate('FAVOURITES.DELETE_MESSAGE_ENDTEXT')}</p>   </br>
          <p>${this.translate.translate('FAVOURITES.CHANGE_MESSAGE_PRETEXT')}</p>
          ${objectsUsingTheProfile.alertMessageObjectList}`,
          modalStyle: ModalStyle.ERROR,
        },
      })
      await modal.present()
      await modal.onDidDismiss()
    } else {
      await this.favouritesService.deleteFavourite(favourite.id)
    }
  }

  public async navToFavouriteDetail(): Promise<void> {
    await this.router.navigate([this.router.url, 'favourites', this.favourite.id])
  }
}
