<ion-buttons>
  <button
    class="action-icon action-icon-large"
    (click)="centerView()"
    *ngIf="model"
    efpTooltip
    [tooltipTitle]="'TOOLBAR.CENTER_VIEW' | translate"
    data-test-selector="planner-center-view">
    recenter
  </button>
</ion-buttons>
