import { CycleBoundaryDrawable, PlanType } from 'formwork-planner-lib'
import paper from 'paper/dist/paper-core'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { SlabModel } from '../model/SlabModel'
import { RenderService } from './render.service'
import { EdgeSelectionService } from './edge-selection.service'
import { BLACK } from '../../../constants/colors'

const DEFAULT_SLAB_STYLE = {
  strokeColor: BLACK,
  strokeWidth: 1,
} as paper.Style

export class SlabRenderService extends RenderService<SlabModel> {
  public constructor(
    model: SlabModel,
    paperScope: paper.PaperScope,
    planVisibilitySettings: PlanVisibilitySettings,
    edgeSelectionService?: EdgeSelectionService
  ) {
    super(model, paperScope, PlanType.SLAB, planVisibilitySettings, edgeSelectionService)
  }

  protected getMeshStyle(): paper.Style {
    return DEFAULT_SLAB_STYLE
  }

  protected refreshLabels(cycleBoundaries?: CycleBoundaryDrawable[]): void {
    const path = this.previewPath ?? this.path

    if (!path.children) {
      return
    }

    path.children.forEach((childPath) => {
      this.labelService.generateAngleLabelsForPath(childPath, true)
      this.labelService.generateLengthLabelsForPath(
        childPath,
        false,
        this.model.drawSetting.measurementUnit,
        cycleBoundaries
      )
    })
  }
}
