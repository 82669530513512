<efp-list-tile-styling>
  <ion-item lines="none" class="option-menu-item" button="true" (click)="navToFavouriteDetail()">
    <div class="d-flex-col gap-2">
      <ng-container>
        <div class="d-flex title-subtitle-container">
          <ion-title color="primary" class="primary-title">
            <ng-container *ngIf="favourite.isStandard; else notStandardTitle">
              {{ 'FORMWORK.' + favourite.name | translate }}
            </ng-container>
            <ng-template color="primary" #notStandardTitle>
              {{ favourite.name }}
            </ng-template>
          </ion-title>

          <ion-note>
            <ng-container *ngIf="linkedObjectsAmount === 1; else linkedObjPlural">
              (Linked to {{ linkedObjectsAmount }} plan)
            </ng-container>
            <ng-template color="primary" #linkedObjPlural>
              (Linked to {{ linkedObjectsAmount }} plans)
            </ng-template>
          </ion-note>
        </div>
        <div class="d-flex gap-2">
          <efp-badge>
            <ng-container *ngIf="favourite.isStandard; else notStandardBadge">
              {{ 'FAVOURITES.BASE_PROFILE' | translate }}
            </ng-container>
            <ng-template #notStandardBadge>
              {{ 'FAVOURITES.CUSTOM_PROFILE' | translate }}
            </ng-template>
          </efp-badge>
          <efp-badge>
            <ng-container>
              {{ formworkVersion }}
            </ng-container>
          </efp-badge>
          <efp-badge *ngIf="isOutdated">
            <ng-container>
              {{ 'FAVOURITES.OUTDATED' | translate }}
            </ng-container>
          </efp-badge>
        </div>
      </ng-container>
    </div>
    <div
      class="option-menu-container"
      (click)="presentFavouriteActionSheet(favourite, $event)"
      role="button"
      tabindex="0"
      slot="end"
      (keydown.enter)="presentFavouriteActionSheet(favourite)">
      <ion-icon
        name="ellipsis-vertical"
        color="primary"
        attr.data-test-selector="favourites-overview-list-item-action-{{
          favourite.id
        }}"></ion-icon>
    </div>
  </ion-item>
</efp-list-tile-styling>
