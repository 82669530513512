import { Injectable } from '@angular/core'
import { AlertController, ModalController } from '@ionic/angular'
import { Article } from '../models/article'
import { Stock } from '../models/stock'
import { Translation } from './translation.service'
import { StockRepository } from '../repositories/stock.repository'
import { mapStockToCreateStockCommandParams } from './dao/stock.dao'
import { ArticleRepository } from '../repositories/article.repository'
import {
  ModalDismissAction,
  SimpleModalComponent,
} from '../shared/components/simple-modal/simple-modal.component'
import {
  DynamicInput,
  DynamicFormComponent,
} from '../shared/components/dynamic-form/dynamic-form.component'
import { AbstractControl, ValidationErrors } from '@angular/forms'

class StockImportError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class StockService {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly alertCtrl: AlertController,
    private readonly translate: Translation,
    private readonly stockRepository: StockRepository,
    private readonly articleRepository: ArticleRepository
  ) {}

  public async importStockListFromFile(
    text: string,
    fileName: string,
    useOptimisticCreation: boolean,
    existingStock?: Stock
  ): Promise<Stock | null> {
    const stockName = fileName.substring(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('.'))

    const importedArticles = await this.importArticlesFromFile(text, fileName)

    if (importedArticles.length < 1) {
      return null
    } else if (existingStock) {
      const newStockArticles = existingStock.articles ?? []

      for (const article of importedArticles) {
        article.stockId = existingStock.id
        const existingArticle = newStockArticles.find((a) => a.articleId === article.articleId)
        try {
          if (existingArticle) {
            existingArticle.amount += article.amount
            await this.articleRepository.update({
              ...existingArticle,
            })
          } else {
            await this.articleRepository.create(article)
            newStockArticles.push(article)
          }
        } catch {
          return null
        }
      }

      return {
        id: existingStock.id,
        name: existingStock.name,
        date: existingStock.date,
        articles: newStockArticles,
      }
    } else {
      return this.createStock(useOptimisticCreation, stockName, importedArticles)
    }
  }

  public async createStock(
    useOptimisticCreation: boolean,
    stockName?: string,
    articleList?: Article[]
  ): Promise<Stock | null> {
    const stocks: Stock[] = await this.stockRepository.fetchAll()
    const stockNames: string[] = stocks?.map((stock) => stock.name)

    const uniqueName = StockService.createUniqueStockName(
      stockNames ?? [],
      stockName ?? this.translate.translate('STOCK.IMPORT_DEFAULT_NAME')
    )

    const modal = await this.updateOrCreateStockModal(uniqueName, stocks)
    await modal.present()
    const { data, role } = await modal.onDidDismiss()

    if (role === 'cancel') {
      return null
    } else if (role === ModalDismissAction.CONFIRM && data) {
      const stockList: Stock = {
        id: 0,
        name: data,
        date: new Date(),
        articles: articleList,
      }

      try {
        return await this.stockRepository.createStockWithArticles(
          mapStockToCreateStockCommandParams(stockList),
          useOptimisticCreation
        )
      } catch {
        return null
      }
    }

    return null
  }

  public async updateOrCreateStockModal(
    value: string,
    stockEntries?: Stock[],
    currentStock?: Stock
  ): Promise<HTMLIonModalElement> {
    const stocks = stockEntries ? stockEntries : await this.stockRepository.fetchAll()
    const stockNames: string[] = stocks?.map((item) => item.name)

    const inputs: DynamicInput[] = [
      {
        type: 'text',
        value,
        placeholder: value,
      },
    ]

    const validationFn = (control: AbstractControl): ValidationErrors => {
      const errors: ValidationErrors = {}
      if (stockNames?.includes(control.value) && currentStock?.name !== control.value)
        errors.duplicateName = this.translate.translate('STOCK.IMPORT_ERROR_UNIQUE_NAME')
      if (!control.value) errors.emptyInput = this.translate.translate('STOCK.IMPORT_SET_NAME')
      return errors
    }

    return await this.modalCtrl.create({
      component: SimpleModalComponent,
      backdropDismiss: false,
      cssClass: 'modal-responsive-max-w-400',
      componentProps: {
        title: currentStock ? 'GENERAL.RENAME' : 'STOCK.IMPORT_SET_NAME',
        cancelButtonLabel: 'GENERAL.CANCEL',
        confirmButtonLabel: 'GENERAL.OK',
        dynamicComponent: DynamicFormComponent,
        dynamicComponentProps: { options: inputs, validationFn },
      },
    })
  }

  private async importArticlesFromFile(text: string, fileName: string): Promise<Article[]> {
    let articles: Article[] = []
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1)
    if (extension === 'ma7') {
      try {
        articles = await this.importArticlesFromMa7StockFile(text)
      } catch (_: unknown) {
        await this.showStockImportErrorAlert(
          undefined,
          this.translate.translate('STOCK.IMPORT_ERROR_MA7')
        )
      }
    } else if (extension === 'csv') {
      try {
        articles = await this.importArticlesFromCsvStockFile(text)
      } catch (error: unknown) {
        const stockErrorMessage = error instanceof StockImportError ? error.message : undefined
        if (stockErrorMessage) {
          await this.showStockImportErrorAlert(
            this.translate.translate('STOCK.IMPORT_ERROR'),
            stockErrorMessage
          )
        } else {
          await this.showStockImportErrorAlert(
            undefined,
            this.translate.translate('STOCK.IMPORT_ERROR_CSV')
          )
        }
      }
    } else {
      // wrong format
      await this.showStockImportErrorAlert(
        undefined,
        this.translate.translate('STOCK.IMPORT_WRONG_FORMAT')
      )
    }

    const duplicateArticleId = this.checkForDuplicates(articles)
    if (duplicateArticleId) {
      articles = []
      await this.showStockImportErrorAlert(
        undefined,
        this.translate
          .translate('STOCK.IMPORT_DUPLICATE_ERROR')
          .replace('{{articleId}}', duplicateArticleId)
      )
    }

    return articles
  }

  private async importArticlesFromMa7StockFile(text: string): Promise<Article[]> {
    const domParser = new DOMParser()
    const xmlDocument = domParser.parseFromString(text, 'text/xml')
    const xmlArticles: HTMLCollection = xmlDocument.getElementsByTagName('ListLine')
    const articles: Article[] = []

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < xmlArticles.length; i++) {
      const amount: number = +(xmlArticles[i].getElementsByTagName('Quantity')[0].textContent ?? 0)
      const articleName: string | null =
        xmlArticles[i].getElementsByTagName('Designation')[0].textContent
      const id: string | null = xmlArticles[i].getElementsByTagName('ArticleNumber')[0].textContent

      if (articleName && id) {
        const article: Article = {
          id: 0,
          amount,
          articleId: id,
          name: articleName,
          stockId: 0,
        }

        articles.push(article)
      }
    }

    return articles
  }

  private async importArticlesFromCsvStockFile(text: string): Promise<Article[]> {
    const strDelimiter = ';'
    const objPattern = new RegExp(
      '(\\' +
        strDelimiter +
        '|\\r?\\n|\\r|^)' +
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        '([^"\\' +
        strDelimiter +
        '\\r\\n]*))',
      'gi'
    )

    const articles: Article[] = []
    const parsedCsvData: string[][] = [[]]
    let arrMatches: RegExpExecArray | null = null

    while ((arrMatches = objPattern.exec(text))) {
      const strMatchedDelimiter = arrMatches[1]
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        parsedCsvData.push([])
      }

      let strMatchedValue
      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
      } else {
        strMatchedValue = arrMatches[3]
      }
      parsedCsvData[parsedCsvData.length - 1].push(strMatchedValue)
    }

    for (let i = 1; i < parsedCsvData.length; i++) {
      // sometimes csv [from numbers - Mac] adds an empty row
      const row = parsedCsvData[i]
      if (row[0].length > 0) {
        const id = row[0]
        const amount: number = +row[1]
        const name: string = row[2]

        if (id == null || id.length < 8 || id.length > 9) {
          throw this.createCsvImportError(i + 1, this.translate.translate('GENERAL.ARTICLE_NUMBER'))
        }

        if (isNaN(amount) || amount < 0 || amount > 9999) {
          throw this.createCsvImportError(i + 1, this.translate.translate('GENERAL.QUANTITY'))
        }

        if (name.length < 1) {
          throw this.createCsvImportError(i + 1, this.translate.translate('GENERAL.ARTICLE_NAME'))
        }

        if (amount !== undefined && name && id) {
          const article: Article = {
            id: 0,
            amount,
            articleId: id,
            name,
            stockId: 0,
          }

          articles.push(article)
        }
      }
    }
    return articles
  }

  private createCsvImportError(rowNumber: number, columnName: string): Error {
    const columnLabel = this.translate.translate('STOCK.IMPORT_COLUMN')
    const rowNumberLabel = this.translate.translate('STOCK.IMPORT_ROW')

    return new StockImportError(`
${columnLabel}: <b>${columnName}</b></br>
${rowNumberLabel}: <b>${rowNumber}</b>`)
  }

  private async showStockImportErrorAlert(
    subHeader: string | undefined,
    message: string | undefined
  ): Promise<void> {
    const alert = await this.alertCtrl.create({
      cssClass: 'alertStyle',
      header: this.translate.translate('GENERAL.ERROR'),
      subHeader,
      message,
      buttons: [
        {
          text: this.translate.translate('GENERAL.OK'),
        },
      ],
    })
    await alert.present()
  }

  public static createUniqueStockName(stockNames: string[], newName: string): string {
    if (!stockNames.includes(newName)) return newName

    let index = 1
    let uniqueName = newName

    while (stockNames.includes(uniqueName)) {
      uniqueName = `${newName}_${index}`
      index++
    }

    return uniqueName
  }

  async createDeleteArticleAlert(article: Article): Promise<boolean> {
    let shouldDeleteArticle = false
    const alert = await this.alertCtrl.create({
      cssClass: ['alertStyle', 'alertStyleTwoButtons'],
      header: this.translate.translate('STOCK.ARTICLE.DELETE_HEADER', {
        articleName: article.name,
      }),
      message: this.translate.translate('STOCK.ARTICLE.DELETE_MESSAGE'),
      buttons: [
        {
          text: this.translate.translate('GENERAL.CANCEL'),
        },
        {
          text: this.translate.translate('GENERAL.OK'),
          handler: () => {
            shouldDeleteArticle = true
          },
        },
      ],
    })
    await alert.present()
    await alert.onDidDismiss()

    if (shouldDeleteArticle) {
      await this.articleRepository.delete(article)
    }

    return shouldDeleteArticle
  }

  private checkForDuplicates(articles: Article[]): string | undefined {
    const foundArticleIds = new Map()
    for (const article of articles) {
      if (foundArticleIds.has(article.articleId)) {
        return article.articleId
      }
      foundArticleIds.set(article.articleId, '')
    }

    return
  }
}
