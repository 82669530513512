<div class="mobile-container">
  <div class="d-flex pt-3">
    <ion-label class="sub-title">{{
      'FAVOURITES.PROFILE.LIST_SOLUTIONS_HEADING' | translate
    }}</ion-label>
  </div>

  <p class="header-description">
    <ion-note>
      {{ 'FAVOURITES.PLANSETTINGS.FAVOURITES_CALC_HINT' | translate }}
    </ion-note>
  </p>
</div>

<div *ngIf="this.selectedTypes" class="list">
  <ng-container *ngFor="let type of this.selectedTypes">
    <efp-list-tile-styling>
      <div class="d-flex-col gap-2">
        <div class="mobile-container">
          <ion-label class="label">
            {{ type.ID | favTranslate }}
          </ion-label>
        </div>
        <div class="mobile-container full-width">
          <ion-item lines="none" button="true" (click)="navToFavouriteDetail(type.ID)">
            <div class="d-flex flex-1 gap-2">
              <efp-thumbnail-action-item
                class="basis-56"
                [title]="type.ID | favTranslate"
                [imgSrc]="this.imgUrl(type.Loesung[0].ID)"></efp-thumbnail-action-item>
              <div class="flex-6 d-flex-col title-subtitle-container" color="primary">
                <div class="primary-title">
                  {{ type.Loesung[0].ID | favTranslate }}
                </div>
                <efp-badge [isActive]="true">
                  {{ 'FAVOURITES.PROFILE.SOLUTIONS.RANK_FAVOURED' | translate }}
                </efp-badge>
              </div>
              <div class="icon-container">
                <ion-icon src="/assets/icon/ic_edit.svg" color="primary"> </ion-icon>
              </div>
            </div>
          </ion-item>
        </div>
      </div>
    </efp-list-tile-styling>
  </ng-container>
  <div class="d-flex link-container pb-3">
    <button class="button button-secondary" (click)="navToFavouriteDetail()">
      {{ 'FAVOURITES.PROFILE.SOLUTIONS.SHOW_ALL' | translate }}
      <span class="button-icon">chevron_right</span>
    </button>
  </div>
</div>
