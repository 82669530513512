<ion-header>
  <ion-toolbar>
    <ion-row>
      <ion-col size="3">
        <ion-buttons>
          <button
            class="action-icon action-icon-large"
            (click)="closeModal()"
            data-test-selected="result-2d-viewer-pdf-export-close">
            close
          </button>
        </ion-buttons>
      </ion-col>
      <ion-col size="6">
        <ion-title class="ion-text-center">
          <div class="ion-text-wrap">
            {{ 'SCREENSHOT.CREATE_PDF' | translate }}
          </div>
        </ion-title>
      </ion-col>
      <ion-col size="3"></ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item-divider>
    <div>{{ 'SCREENSHOT.ADD_TO_PDF' | translate }}</div>
  </ion-item-divider>

  <ion-list *ngIf="screens">
    <ion-item>
      <ion-toggle
        color="primary"
        labelPlacement="start"
        justify="space-between"
        [(ngModel)]="includeTotalStock"
        >{{ 'STOCK.RESULT.PIECE_LIST' | translate }}</ion-toggle
      >
    </ion-item>

    <ion-item *ngIf="showStockPerCycle">
      <ion-toggle
        color="primary"
        labelPlacement="start"
        justify="space-between"
        [(ngModel)]="includeCycleStock">
        {{ 'SCREENSHOT.STOCK_PER_CYCLE' | translate }}
      </ion-toggle>
    </ion-item>

    <ion-item-divider id="dividerOrder">
      <div>{{ 'SCREENSHOT.SCREENSHOT_ORDER' | translate }}</div>
    </ion-item-divider>

    <div *ngFor="let screen of screens">
      <ion-item>
        <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="screen.useInPdf">
          <div class="flex pl-1">
            <ion-img
              [src]="screen.screenshot"
              class="ion-margin-end ion-margin-vertical screenImage"
              color="primary">
            </ion-img>
            <div class="justify-center d-flex-col">
              <ion-label class="ion-font-bold screenName">{{ screen.name }}</ion-label>
              <ion-label class="ion-date ion-ma-top-5" id="screenDate"
                >{{ 'SCREENSHOT.CREATED' | translate }} {{ screen.date | efpDate }}</ion-label
              >
            </div>
          </div>
        </ion-checkbox>
      </ion-item>
    </div>
  </ion-list>
</ion-content>

<!-- button sometimes invisble on android -> position absolte workaround instead of sticky -->
<div class="flex flex-col items-end px-lg">
  <button
    id="btnStyle"
    (click)="exportPDF()"
    [disabled]="!hasIncludedScreens && !includeTotalStock"
    class="flex button btn-workraound">
    {{ 'SCREENSHOT.PDF_EXPORT' | translate }}
  </button>
</div>

<div class="btnStyle-overscroll-placeholder"></div>
