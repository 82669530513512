<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <button (click)="closeModal()" class="action-icon">close</button>
    </ion-buttons>
    <ion-title size="large">{{ header }}</ion-title>
  </ion-toolbar>
</ion-header>
<div class="px-5 py-1.5">
  {{ message }}
  <div class="d-flex align-center gap-0.5">
    <a [href]="'mailto:' + email">
      {{ email }}
    </a>
    <button (click)="copyToClipboard()" class="action-icon">
      <ng-container *ngIf="!copiedToClipboard; else copied">content_copy</ng-container>
      <ng-template #copied> <span class="action-icon">check</span> </ng-template>
    </button>
  </div>
</div>
