import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { Browser } from '@capacitor/browser'
import { PrimaryMenuService } from '../../../services/primary-menu.service'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { Translation } from '../../../services/translation.service'
import { CONTACT_EMAIL } from '../../../constants/emails'
import { FeedbackComponent } from '../feedback/feedback.component'
import { TopSafeAreaBackgroundColor } from '../safe-area-top/safe-area-top.component'
import { ContactComponent } from '../contact/contact.component'
import { Screenshot } from 'capacitor-screenshot'
import { Capacitor } from '@capacitor/core'
import { PrivacyPolicyRepository } from '../../../repositories/privacy-policies.repository'
import { AppSettingsRepository } from '../../../repositories/app-settings.repository'
import { Subject, filter, takeUntil } from 'rxjs'
import { AppSettingsModel, PrivacyPolicyModel } from '../../../../generated/efp-api'

@Component({
  selector: 'efp-primary-menu',
  templateUrl: './primary-menu.component.html',
  styleUrls: ['./primary-menu.component.scss'],
})
export class PrimaryMenuComponent implements OnInit {
  @Input() collapsable?: boolean

  public topSafeAreaBackgroundColor = TopSafeAreaBackgroundColor.Transparent
  public privacyPolicy?: PrivacyPolicyModel

  private destroy$ = new Subject<void>()

  constructor(
    public readonly translate: Translation,
    private readonly router: Router,
    private readonly primaryMenuService: PrimaryMenuService,
    private readonly socialSharing: SocialSharing,
    private readonly modalCtrl: ModalController,
    private readonly privacyPolicyRepository: PrivacyPolicyRepository,
    private readonly appSettingsRepository: AppSettingsRepository
  ) {}

  public ngOnInit(): void {
    this.appSettingsRepository.appSettings$
      .pipe(
        takeUntil(this.destroy$),
        filter((appsettings): appsettings is AppSettingsModel => !!appsettings)
      )
      .subscribe((appSettings) => {
        if (!this.privacyPolicy || this.privacyPolicy.country !== appSettings.country) {
          void this.privacyPolicyRepository.getPrivacyPolicy().then((privacyPolicy) => {
            this.privacyPolicy = privacyPolicy
          })
        }
      })
  }

  public async navTo(route: string[]): Promise<void> {
    await this.router.navigate(route)
  }

  public openUserInformation(): void {
    void Browser.open({
      url: 'https://www.doka.com/at/solutions/overview/anwenderinformation-downloads-schalung',
    })
    this.closeMenu()
  }

  closeMenu(immediate: boolean = false): void {
    this.primaryMenuService.close(immediate)
  }

  async startFeedback(): Promise<void> {
    this.closeMenu(Capacitor.getPlatform() === 'ios')

    await new Promise((resolve) => {
      setTimeout(resolve, 100)
    })

    const screenshot = await Screenshot.take()
    const b64 = `data:image/png;base64,${screenshot.base64}`

    const modal = await this.modalCtrl.create({
      componentProps: { screenshotData: b64 },
      component: FeedbackComponent,
      backdropDismiss: false,
      cssClass: 'soft-edges-modal',
    })
    await modal.present()
    await modal.onDidDismiss()
  }

  public contact(): void {
    const title = this.translate.translate('CONTACT.MAIL_TITLE')
    this.socialSharing
      .canShareViaEmail()
      .then(async () => this.socialSharing.shareViaEmail('', title, [CONTACT_EMAIL]))
      .catch(() => {
        void this.modalCtrl
          .create({
            component: ContactComponent,
            componentProps: {
              header: this.translate.translate('CONTACT.ALERT_TITLE'),
              message: this.translate.translate('CONTACT.ALERT_TEXT'),
              email: CONTACT_EMAIL,
            },
            cssClass: 'soft-edges-modal',
          })
          .then(async (modal) => modal.present())
      })

    this.closeMenu()
  }
}
