<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <div class="flex pr-3 items-center">
      <ion-title size="large">{{ 'ONBOARDING.MODAL_TITLE' | translate }}</ion-title>
      <button class="action-icon action-icon-large" (click)="closeModal()">close</button>
    </div>
  </ion-toolbar>
</ion-header>
<div class="onboarding-content">
  <efp-onboarding-hint-content [onboardingHint]="this.hints[this.index]">
  </efp-onboarding-hint-content>
</div>
<efp-steps-modal-footer>
  <button
    button-start
    *ngIf="index !== 0"
    (click)="goToSlide(this.index - 1)"
    class="action-icon action-icon-large">
    chevron_backward
  </button>

  <ng-container *ngFor="let slide of hints; let i = index" status-indicators>
    <ion-icon
      (click)="goToSlide(i)"
      data-test-selector="feedback-modal-hint-status-indicator"
      aria-hidden="true"
      src="assets/icon/ellipse.svg"
      [ngClass]="{ active: index === i }"></ion-icon>
  </ng-container>

  <ng-container button-end>
    <button
      *ngIf="index < this.hints.length - 1; else lastSlide"
      (click)="goToSlide(this.index + 1)"
      class="action-icon action-icon-large"
      data-test-selector="feedback-modal-next-slide">
      chevron_right
    </button>
    <ng-template #lastSlide>
      <button
        (click)="closeModal()"
        class="action-icon action-icon-large"
        data-test-selector="onboarding-modal-next-hint">
        check
      </button>
    </ng-template>
  </ng-container>
</efp-steps-modal-footer>
