<efp-collapsable-menu
  *ngIf="(hasNavigation || isHomeScreen) && !disablePrimaryMenu"></efp-collapsable-menu>

<ion-header>
  <ion-toolbar>
    <ion-row>
      <ion-col class="flex-1">
        <ion-buttons class="d-flex nav-button-wrapper">
          <efp-open-menu-button
            *ngIf="(hasNavigation || isHomeScreen) && !disablePrimaryMenu"
            [ngClass]="{ 'media-mobile-only': !webCollapsableEnabled }"></efp-open-menu-button>
          <ng-container>
            <ng-content select="#logo"></ng-content>
          </ng-container>
          <button
            class="headerbar-action"
            *ngIf="hasNavigation"
            (click)="navigate()"
            [disabled]="navDisabledAndGray"
            data-test-selector="header-navigate">
            <div class="headerbar-action-icon" *ngIf="isNavStep">home</div>
            <div class="headerbar-action-icon" *ngIf="!isNavStep">arrow_back</div>
          </button>
          <button
            class="action-icon"
            *ngIf="isModal"
            (click)="dismiss()"
            [disabled]="navDisabledAndGray"
            data-test-selector="header-dismiss">
            close
          </button>
        </ion-buttons>
      </ion-col>
      <ion-col class="ion-text-center ion-justify-content-center flex-3 overflow-trunc">
        <ion-title
          class="removeInlinePadding overflow-hidden"
          [ngClass]="{ 'show-title-on-web': showTitleOnWeb }">
          {{ title }}
        </ion-title>
      </ion-col>
      <ion-col class="ion-justify-content-end flex-1 gap-4">
        <efp-request-sync-indicator
          *ngIf="!isNative() && showSyncIndicator"></efp-request-sync-indicator>
        <div class="d-flex gap-2">
          <efp-reset-onboarding-button></efp-reset-onboarding-button>
          <ng-content></ng-content>
          <ion-buttons>
            <ng-content select="[buttons]"></ng-content>
          </ion-buttons>
        </div>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>
