<div
  class="modal"
  [ngClass]="'modal-' + this.modalStyle"
  efpKeyboardShortcut
  (escShortcut)="dismissModalWithAction(modalAction.CANCEL)">
  <div class="modal-content-section">
    <div class="modal-content">
      <span class="modal-icon text-icon-alert-dark" *ngIf="icon">{{ icon }}</span>
      <div class="items-stretch modal-header">
        <span class="modal-title">{{ title | translate }}</span>
        <div class="flex flex-col flex-1 modal-description">
          <div [innerHTML]="innerHTML" *ngIf="innerHTML"></div>
          <ng-container *ngIf="text">
            {{ text | translate }}
          </ng-container>
          <ng-container
            *ngComponentOutlet="dynamicComponent; inputs: dynamicComponentProps"></ng-container>
        </div>
      </div>
    </div>
    <button
      class="modal-close"
      *ngIf="dismissable"
      (click)="dismissModalWithAction(modalAction.CANCEL)"></button>
  </div>

  <div class="modal-action-section" *ngIf="cancelButtonLabel || confirmButtonLabel">
    <button
      class="button button-secondary modal-action"
      *ngIf="cancelButtonLabel"
      (click)="dismissModalWithAction(modalAction.CANCEL)"
      data-test-selector="simple-modal-cancel">
      {{ cancelButtonLabel | translate }}
    </button>
    <button
      class="button button-danger modal-action"
      *ngIf="criticalActionButtonLabel"
      (click)="dismissModalWithAction(modalAction.CRITICAL_ACTION)"
      data-test-selector="simple-modal-critical-action">
      {{ criticalActionButtonLabel | translate }}
    </button>
    <ng-container *ngIf="{ disabled: this.isApplyButtonDisabled$ | async } as obj">
      <button
        class="button modal-action"
        *ngIf="confirmButtonLabel"
        (click)="dismissModalWithAction(modalAction.CONFIRM)"
        data-test-selector="simple-modal-submit"
        [disabled]="confirmButtonDisabled || obj.disabled">
        {{ confirmButtonLabel | translate }}
      </button>
    </ng-container>
  </div>
</div>
