//1055px === 66rem === 'md' tailwind breakpoint
export const EFP_WEB_BREAK_POINT = 1055
export const PORTRAIT_ONLY_BREAK_POINT = 620

export function checkIfMobileLayout(): boolean {
  return window.innerWidth < EFP_WEB_BREAK_POINT
}

export function checkIfPortraitOnly(width: number, height: number): boolean {
  if (width < height) {
    return width < PORTRAIT_ONLY_BREAK_POINT
  } else {
    return height < PORTRAIT_ONLY_BREAK_POINT
  }
}
