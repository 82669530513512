<lib-efpViewer></lib-efpViewer>
<efp-measurement-legend
  [wallHeight]="planSettings ? planSettings.wallHeight : undefined"
  [slabThickness]="planSettings ? planSettings.slabThickness : undefined"
  [slabHeight]="planSettings ? planSettings.slabHeight : undefined"
  [measurementUnitPlanner]="planSettings ? planSettings.measurementUnit : undefined"
  [buildingType]="plan ? plan.buildingType : undefined"
  [additionalSpacingBottom]="plan !== undefined && plan.buildingType === planType.WALL"
  [result]="true"
  [formwork]="planSettings ? planSettings.formworkWall : undefined"></efp-measurement-legend>
<ng-container
  *ngIf="checkIfWallPlan()"
  [efpOnboardingTrigger]="checkAndShareResultOnboardingHint()"
  [triggeredBy]="TriggerType.COMPONENT_RENDERED">
  <efp-onboarding-hints [onboardingHintSeriesKey]="checkAndShareResultOnboardingHint()">
  </efp-onboarding-hints>
</ng-container>
<efp-bottom-menu-container *ngIf="cycleMenuText">
  <efp-primary-planner-toolbar>
    <div class="action-icon-switch md:action-icon-switch-vertical">
      <button
        class="action-icon action-icon-large"
        efpTooltip
        [tooltipTitle]="'TOOLBAR.PERSPECTIVE_VIEW' | translate"
        (click)="switchCameraMode(CameraMode.PERSPECTIVE)"
        [ngClass]="{ 'action-icon-activated': cameraMode === CameraMode.PERSPECTIVE }">
        <ion-icon slot="icon-only" src="assets/icon/grid_perspective.svg"></ion-icon>
      </button>
      <button
        class="action-icon action-icon-large"
        efpTooltip
        [tooltipTitle]="'TOOLBAR.ORTHOGONAL_VIEW' | translate"
        [ngClass]="{ 'action-icon-activated': cameraMode === CameraMode.ORTHOGRAPHIC }"
        (click)="switchCameraMode(CameraMode.ORTHOGRAPHIC)">
        grid_on
      </button>
    </div>
    <button
      class="action-icon action-icon-large"
      (click)="createScreenshot()"
      efpTooltip
      [tooltipTitle]="'TOOLBAR.CREATE_2D_VIEW' | translate">
      add_a_photo
    </button>
    <ion-buttons *ngIf="cycleMenuText && this.viewer.toolbarControl.isWallMode">
      <ng-content></ng-content>
      <ng-container>
        <button
          efpTooltip
          [tooltipTitle]="'TOOLBAR.HIGHLIGHT' | translate"
          class="expandable-btn action-icon action-icon-large"
          (click)="presentHighlightPopover($event)"
          data-test-selector="highlight-menu-3d">
          ink_marker
        </button>
      </ng-container>
    </ion-buttons>
    <efp-toolbar-divider></efp-toolbar-divider>
    <ion-buttons>
      <ng-content></ng-content>
      <ng-container>
        <button
          class="action-icon action-icon-large"
          efpTooltip
          [tooltipTitle]="'TOOLBAR.VISIBILITY_SETTINGS' | translate"
          (click)="presentVisibilityPopover($event)"
          data-test-selector="visibility-menu-3d">
          visibility_off
        </button>
      </ng-container>
    </ion-buttons>
    <button
      class="action-icon action-icon-large"
      (click)="resetCamera()"
      efpTooltip
      [tooltipTitle]="'TOOLBAR.CENTER_VIEW' | translate">
      recenter
    </button>
  </efp-primary-planner-toolbar>
</efp-bottom-menu-container>
<div class="pillMenu justify-center" *ngIf="cycleMenuText && this.viewer.toolbarControl.isWallMode">
  <div class="button-group basis-80">
    <button
      class="button button-secondary flex-0"
      (click)="decrementCycle()"
      [disabled]="cycleIsPlan">
      <span class="button-icon">chevron_backward</span>
    </button>
    <button
      class="button button-secondary flex-1 justify-center"
      (click)="presentCyclePopover($event)">
      <ng-container *ngIf="!this.isOpen">
        {{ cycleMenuText }}
        <span class="button-icon">arrow_drop_up</span>
      </ng-container>
      <ng-container *ngIf="this.isOpen">
        <span class="button-icon" *ngIf="cycleMenuText">close</span>
      </ng-container>
    </button>
    <button
      class="action-icon action-icon-large"
      class="button button-secondary flex-0"
      (click)="incrementCycle()"
      [disabled]="cycleIsDoneOrAll">
      <span class="button-icon"> chevron_forward </span>
    </button>
  </div>
</div>
